import React from "react";
import "./loader.css";

function Loader() {
  return (
    <div className="homeloader">
      <div className="loader-letters">
        <div className="loader-letter text-secondary">S</div>
        <div className="loader-letter text-white">N</div>
        <div className="loader-letter text-tertiary">A</div>
        <div className="loader-letter text-secondary">P</div>
        <div className="loader-letter text-white">B</div>
        <div className="loader-letter text-tertiary">O</div>
        <div className="loader-letter text-secondary">O</div>
        <div className="loader-letter text-white">S</div>
        <div className="loader-letter text-tertiary">T</div>
        <div className="loader-letter text-secondary">E</div>
        <div className="loader-letter text-white">R</div>
      </div>
    </div>
  );
}

export default Loader;
