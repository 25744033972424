import React from 'react';
// import DigiBlog from '../../components/blog/DigiBlog';
import DigiContact from '../../components/contact/DigiContact';
// import NewsLetterTwo from '../../components/cta/NewsLetterTwo';
// import CustomerBrand from '../../components/customer/CustomerBrand';
// import DigiIntegration from '../../components/integration/DigiIntegration';
import DigiWhyChoose from '../../components/others/DigiWhyChoose';
import DigiSerives from '../../components/services/DigiSerives';


import DigiWorkProcess from '../../components/work-process/DigiWorkProcess';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import HeroFourteen from './HeroFourteen';
import ContactFormThree from '../../components/contact/ContactFormThree';
// services category
// import ServicesCategory from '../../components/services/ServicesCategory';
import ServicesCategory2 from '../../components/services/ServicesCategory2';
// import ContactFormTwo from '../../components/contact/ContactFormTwo';
// import ServiceDropdown from "../../components/services/serviceDropdown";

const HomeFourteen = () => {

  return (
    <Layout>
      <Navbar />
      <HeroFourteen />
      {/* <ServiceDropdown /> */}
      <ServicesCategory2 />
      <DigiSerives />
      {/* <ServicesCategory /> */}
      {/* <ContactFormTwo /> */}
      <ContactFormThree />
      {/* <CustomerBrand /> */}
      {/* <DigiSerives /> */}
      {/* <NewsLetterTwo /> */}
      <DigiWhyChoose />
      <DigiWorkProcess />
      {/* <DigiIntegration /> */}
      {/* <DigiBlog /> */}
      {/* <DigiContact /> */}
      <FooterOne
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat bottom left",
        }}
      />
    </Layout>
  );
};

export default HomeFourteen;


