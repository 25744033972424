import React, { useContext, useEffect, useState } from 'react'
import Routers from './routers'
// Swiper CSS
import "swiper/css";
import 'swiper/css/navigation';
//bootstrap
import "bootstrap/dist/js/bootstrap.bundle";
//Modal Video
import "react-modal-video/scss/modal-video.scss";
import adminContext from './context/adminContext';
import Loader from './components/loader/Loader';
// import { Routes, Route } from "react-router-dom";
function App() {

  const context = useContext(adminContext);
  const { loading, GetHomePageData, UserDetails, GetAllServices, GetAllCategories, GetAllPackages, } = context;
  const fetchData = () => {
    GetHomePageData();
    GetAllCategories();
    GetAllServices();
    GetAllPackages();
    UserDetails();
  };
  // Call fetchData on initial render
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {loading ? <Loader /> : null}
      <Routers />
    </>
  )
}

export default App