import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import OffCanvasMenu from "./OffCanvasMenu";
import AdminContext from "../../context/adminContext";
import { Modal } from "antd";

const Navbar = ({ navDark }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  const context = useContext(AdminContext);
  const { categories, API_URL } = context;

  // const filteredServices = services.filter((service) => service.category._id === category._id);

  const Items = [
    {
      categoryName: "Instagram",
      createdAt: "2024-02-04T20:54:09.380Z",
      description: "Instagram",
      icon: "uploadsiconicon-1707080049361.svg",
      updatedAt: "2024-02-04T20:54:09.380Z",
      __v: 0,
      _id: "65bff9712b1f7f356ca174b6",
    },
  ];

  // useEffect(() => {
  //   GetAllCategories();
  // }, [])

  useEffect(() => {
    const stickyheader = document.querySelector(".main-header");
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  const [selectedCategory, setSelectedCategory] = useState(null);
  // console.log(selectedCategory, 'selectedCategory')
  const handleCategoryHover = (category) => {
    setSelectedCategory(category);
  };

  const handleModalClose = () => {
    setSelectedCategory(null);
  };

  return (
    <>
      <header
        className={`main-header ${
          navDark ? "position-absolute " : ""
        } w-100 position-absolute `}
      >
        <nav
          className={`navbar navbar-expand-xl z-10 ${
            navDark ? "navbar-dark" : "navbar-light"
          } sticky-header ${scroll > headerTop ? "affix" : ""}`}
        >
          <div className="container d-flex align-items-center justify-content-lg-between position-relative">
            <Link
              to="/"
              className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none"
            >
              <img
                // src="assets/img/logo-white.png"
                src="assets/img/smm-logo/snapBooster.png"
                alt="logo"
                className="img-fluid logo-white"
              />
              <img
                // src="assets/img/logo-color.png"
                src="assets/img/smm-logo/snapBooster.png"
                alt="logo"
                className="img-fluid logo-color"
              />
            </Link>

            <Link
              className="navbar-toggler position-absolute right-0 border-0"
              // to="#offcanvasWithBackdrop"
              role="button"
            >
              <span
                className="far fa-bars"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasWithBackdrop"
                aria-controls="offcanvasWithBackdrop"
              ></span>
            </Link>

            <div className="collapse navbar-collapse justify-content-center">
              <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link"
                    to="/"
                    role="button"
                    // data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Home
                  </Link>
                  {/* <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                    <div className="dropdown-grid rounded-custom width-full">
                      <div className="dropdown-grid-item">
                        <h6 className="drop-heading">Different Demos</h6>
                        <Link to="/" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                            1
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">Saas Company 1</div>
                            <p>
                              It's for <strong>SaaS Software</strong> Company
                            </p>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div> */}
                </li>
                <li>
                  <Link to="/contact-us" className="nav-link">
                    Contacts
                  </Link>
                </li>
                <li>
                  <Link to="/faq" className="nav-link">
                    FAQ
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    // href="/"
                    to="/"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Services
                  </Link>
                  <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                    <div className="dropdown-grid rounded-custom width-full">
                      {categories?.map((category) => (
                        <div key={category._id} className="dropdown-grid-item">
                          <a
                            // to={`/serviceForm?id=${category._id}`}
                            className="dropdown-link"
                          >
                            <span
                              className="me-2"
                              onMouseEnter={() =>
                                handleCategoryHover(category._id)
                              }
                              // onMouseLeave={handleModalClose}
                            >
                              <img
                                src={`${API_URL}/${category?.icon}`}
                                loading="lazy"
                                width="24"
                                alt={category.categoryName}
                                // onClick={() => updateState({ isShow: !isShow })}
                              />
                            </span>
                            <div
                              onMouseEnter={() =>
                                handleCategoryHover(category._id)
                              }
                              className="drop-title"
                            >
                              {category.categoryName}
                            </div>
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                </li>
                {localStorage.getItem("token") ? (
                  <>
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        href="/"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        User Profile
                      </a>
                      <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                        <div className="dropdown-grid rounded-custom ">
                          <div className="dropdown-grid-item">
                            <Link to="/user-profile" className="dropdown-link">
                              <div className="dropdown-info">
                                <div className="drop-title">User Profile</div>
                                <div className="drop-title">
                                  <p className="  text-success">
                                    {localStorage.getItem("email")}
                                  </p>
                                </div>
                                {/* <p>
                              It's for <strong>SaaS Software</strong> Company
                            </p> */}
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </li>
                  </>
                ) : (
                  ""
                )}
              </ul>
            </div>

            <div className="action-btns text-end me-5 me-lg-0 d-none d-md-block d-lg-block">
              {/* <Link to="#" className="btn  btn-primary rounded-pill">
                0.00 $
              </Link> */}
              {!localStorage.getItem("token") ? (
                <Link
                  to="/login"
                  className="btn btn-link text-decoration-none me-2"
                >
                  Sign In
                </Link>
              ) : (
                <Link
                  to="/login"
                  className="btn btn-link text-decoration-none me-2"
                  onClick={() => localStorage.removeItem("token")}
                >
                  Logout
                </Link>
              )}
              {/* <Link to="/request-for-demo" className="btn btn-primary rounded-pill">
                Get Started
              </Link> */}
            </div>
            <OffCanvasMenu />
          </div>
        </nav>
      </header>
      <ServiceModal
        visible={!!selectedCategory}
        // services={selectedCategory ? selectedCategory.services : []}
        services2={selectedCategory ? selectedCategory : []}
        closeModal={handleModalClose}
      />
    </>
  );
};

export default Navbar;

const ServiceModal = ({ visible, services2, closeModal }) => {
  // console.log(visible, services2, closeModal)
  const context = useContext(AdminContext);
  const { services, API_URL } = context;
  // console.log(services2, 'services')
  const filteredServices2 = services?.filter(
    (service) => service?.category?._id === services2
  );
  // console.log(filteredServices2, 'filteredServices2')
  return (
    <Modal
      title="Services"
      visible={visible}
      onCancel={closeModal}
      footer={null}
    >
      <ul>
        {filteredServices2 && filteredServices2?.length > 0 ? (
          filteredServices2?.map((service) => (
            <Link key={service?._id} to={`/serviceForm?id=${service?._id}`}>
              <li>
                <img
                  src={`${API_URL}/${service?.serviceIcon}`}
                  loading="lazy"
                  width="30"
                  alt={service?.serviceIcon}
                  className="me-2 mx-3"
                />
                <a
                  className="mx-2 mt-2 fs-4"
                  href={`/serviceForm?id=${service?._id}`}
                >
                  {service?.category?.categoryName} {service?.serviceName}
                </a>
              </li>
            </Link>
          ))
        ) : (
          <div className="text-center">No services available</div>
        )}
      </ul>
    </Modal>
  );
};
