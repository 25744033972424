
import React, { useState, useContext, useEffect } from 'react';
import Layout from '../../../layout/Layout';
import PageMeta from '../../../components/common/PageMeta';
import Navbar from '../../../layout/Header/Navbar';
import FooterOne from '../../../layout/Footer/FooterOne';
import PageHeader from '../../../components/common/PageHeader';
// import { Link } from 'react-router-dom';
import adminContext from '../../../context/adminContext';
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { Button, Space, Table, Modal } from 'antd';
import { __formatDate } from '../../../utils/function';
import { EyeOutlined } from '@ant-design/icons';

const UserView = () => {
    return (
        <>
            <Layout>
                <PageMeta title="Snap Booster" />
                {/* <Navbar /> */}
                <Navbar navDark />
                <PageHeader
                // title="User Panel"
                // desc=""
                />
                <UserBox />
                <FooterOne />
                {/* <FooterOne footerLight /> */}
            </Layout>
        </>
    );
};
export default UserView;

const UserBox = () => {
    // context
    const { UserDetails, user, GetOrderByUserId, userOrder, UpdateProfile } = useContext(adminContext);
    const userId = user._id
    let nevigate = useNavigate();

    useEffect(() => {
        UserDetails()
        const userId = user._id
        GetOrderByUserId(userId)
    }, [userId]);

    // console.log(userOrder);
    const handleLogout = () => {
        localStorage.removeItem('token');
        window.location.href = '/login';
    };

    const [show, setShow] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const id = localStorage.getItem("id");

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            username: user.username || '',
            password: '',
        },
        validationSchema: Yup.object({
            // email: Yup.string().required("Please Enter Your Email"),
            // password: Yup.string().required("Please Enter Your Password"),
            username: Yup.string().required("Please Enter Your UserName"),
            password: Yup.string().min(6).max(25),
        }),
        onSubmit: async (values) => {
            // console.log("values", values)
            setIsSubmitting(true);
            let response = await UpdateProfile(id, values.username, values.password)
            // let userdata = await UpdateProfile(id, values.username, values.password, profile)
            // if (userdata) {
            //     UserDetails()
            // }
            console.log("response", response)
            // response && setIsSubmitting(false);
            if (response) {
                setIsSubmitting(false)
            }
            // res?alert('login'):alert('error')
            // response && nevigate('/user-profile')
            // window.location.reload(true);
        }
    });

    return (
        <>
            <section className='support-content ptb-120 '>
                <div className='container'>
                    <div className='row justify-content-between'>
                        <div className='col-lg-3 col-md-3  d-n d-md-block d-lg-block'>
                            <div className='support-article-sidebar sticky-sidebar'>
                                <div
                                    className='nav flex-column nav-pills support-article-tab bg-light rounded-custom p-5'
                                    id='v-pills-support'
                                    role='tablist'
                                    aria-orientation='vertical'
                                >
                                    <button
                                        className='nav-link active '
                                        data-bs-target='#support-tab-1'
                                        data-bs-toggle='pill'
                                        type='button'
                                        role='tab'
                                        aria-selected='true'
                                    >
                                        <strong className='fs-5'>User Profile</strong>
                                    </button>
                                    <button
                                        className='nav-link'
                                        data-bs-target='#support-tab-2'
                                        data-bs-toggle='pill'
                                        type='button'
                                        role='tab'
                                        aria-selected='false'
                                    >
                                        <strong className='fs-5'>Orders</strong>
                                    </button>
                                    <button
                                        className='nav-link'
                                        // data-bs-target='#support-tab-3'
                                        // data-bs-toggle='pill'
                                        type='button'
                                        role='tab'
                                        aria-selected='false'
                                        onClick={handleLogout}
                                    >
                                        <strong className='fs-5'>Logout</strong>
                                    </button>
                                </div>
                                {/* <div className='bg-light p-5 mt-4 rounded-custom quick-support'>
                                    <Link
                                        to='contact-us.html'
                                        className='text-decoration-none text-muted d-flex align-items-center py-2'
                                    >
                                        <div className='quick-support-icon rounded-circle bg-success-soft me-3'>
                                            <i className='far fa-ballot-check text-success'></i>
                                        </div>
                                        <div className='contact-option-text'>
                                            Quick Support Form
                                        </div>
                                    </Link>
                                    <Link
                                        to='mailto:info@themetags.com'
                                        className='text-decoration-none text-muted d-flex align-items-center py-2'
                                    >
                                        <div className='quick-support-icon rounded-circle bg-primary-soft me-3'>
                                            <i className='far fa-envelope text-primary'></i>
                                        </div>
                                        <div className='contact-option-text'>
                                            info@themetags.com
                                        </div>
                                    </Link>
                                </div> */}
                            </div>
                        </div>
                        <div className='col-lg-9 col-md-9'>
                            <div className='tab-content' id='v-pills-supportContent'>
                                <div
                                    className='tab-pane fade show active'
                                    id='support-tab-1'
                                    role='tabpanel'
                                >
                                    <div className='support-article-wrap'>
                                        <h2>User Details</h2>
                                        <form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >
                                            <div className="row">
                                                {/* <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Name"
                                                            value={user.username}
                                                        />
                                                    </div>
                                                </div> */}
                                                {/* <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Last Name"
                                                        />
                                                    </div>
                                                </div> */}
                                                <div className='col-sm-12 col-lg-6'>
                                                    <label htmlFor='name' className='mb-1'>
                                                        Name <span className='text-danger'>*</span>
                                                    </label>
                                                    <div className='input-group mb-3'>
                                                        <input
                                                            className={`form-control ${validation.touched.username && validation.errors.username ? 'is-invalid' : ''}`}
                                                            id='name'
                                                            required
                                                            aria-label='name'
                                                            name="username"
                                                            type="text"
                                                            placeholder="Enter Your Name"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.username || ""}
                                                        />
                                                        {validation.touched.username && validation.errors.username ? (
                                                            <div className='invalid-feedback'>{validation.errors.username}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <label htmlFor='name' className='mb-1'>
                                                        Email <span className='text-danger'></span>
                                                    </label>
                                                    <div className="mb-3">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Email"
                                                            value={user.email}
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Change Your Password"

                                                        />
                                                    </div>
                                                </div> */}
                                                <div className='col-sm-12 col-lg-6'>
                                                    <label htmlFor='password' className='mb-1'>
                                                        Update Password <span className='text-danger'>*</span>
                                                    </label>
                                                    <div className='input-group mb-3'>
                                                        <input
                                                            className={`form-control ${validation.touched.password && validation.errors.password ? 'is-invalid' : ''}`}
                                                            id='password'
                                                            required
                                                            aria-label='Password'
                                                            name="password"
                                                            // type="password"
                                                            type={show ? "text" : "password"}
                                                            placeholder="Enter New Password"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.password || ""}
                                                        />
                                                        <button onClick={() => setShow(!show)} className="btn btn-dark " type="button" id="password-addon">
                                                            <i className={show ? 'fas fa-eye' : 'fas fa-eye-slash'}></i>
                                                            {/* <i class="fas fa-eye-slash"></i> */}
                                                        </button>
                                                        {validation.touched.password && validation.errors.password ? (
                                                            <div className='invalid-feedback'>{validation.errors.password}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-3 mt-2">
                                                    <button className="mt-4 btn rounded-pill bg-orange">
                                                        {isSubmitting ? 'Submitting...' : 'Submit'}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                        {/* <ul className='support-article-list list-unstyled mt-4'>
                                            <li className='py-4 border-top border-light'>
                                                <Link
                                                    to='/help-center-single'
                                                    className='text-decoration-none d-block text-muted'
                                                >
                                                    <h3 className='h5 support-article-title'>
                                                        What is the monthly cost of your app?
                                                    </h3>
                                                    <p>
                                                        Credibly evisculate mission-critical products before
                                                        quality e-services. Intrinsicly evisculate
                                                        e-business best practices productivate standardized
                                                        convergence...
                                                    </p>
                                                    <span className='btn-link text-decoration-none read-more-link'>
                                                        Read More{' '}
                                                        <i className='far fa-arrow-right ms-2'></i>
                                                    </span>
                                                </Link>
                                            </li>
                                            <li className='py-4 border-top border-light'>
                                                <Link
                                                    to='support-single.html'
                                                    className='text-decoration-none d-block text-muted'
                                                >
                                                    <h3 className='h5 support-article-title'>
                                                        Do you have any local branches?
                                                    </h3>
                                                    <p>
                                                        Rapidiously incubate alternative infrastructures
                                                        with high standards in e-markets. Intrinsicly
                                                        evisculate e-business best practices standardized...
                                                    </p>
                                                    <span className='btn-link text-decoration-none read-more-link'>
                                                        Read More{' '}
                                                        <i className='far fa-arrow-right ms-2'></i>
                                                    </span>
                                                </Link>
                                            </li>
                                            <li className='py-4 border-top border-light'>
                                                <Link
                                                    to='support-single.html'
                                                    className='text-decoration-none d-block text-muted'
                                                >
                                                    <h3 className='h5 support-article-title'>
                                                        What do I need to create an account?
                                                    </h3>
                                                    <p>
                                                        {' '}
                                                        Intrinsicly evisculate e-business best practices
                                                        productivate standardized convergence. Credibly
                                                        evisculate mission-critical alternative
                                                        infrastructures with high standards in e-markets...
                                                    </p>
                                                    <span className='btn-link text-decoration-none read-more-link'>
                                                        Read More{' '}
                                                        <i className='far fa-arrow-right ms-2'></i>
                                                    </span>
                                                </Link>
                                            </li>
                                            <li className='py-4 border-top border-light'>
                                                <Link
                                                    to='support-single.html'
                                                    className='text-decoration-none d-block text-muted'
                                                >
                                                    <h3 className='h5 support-article-title'>
                                                        Are you open for new podcast guests?
                                                    </h3>
                                                    <p>
                                                        Rapidiously incubate alternative infrastructures
                                                        with high standards in e-markets. Intrinsicly
                                                        evisculate e-business best practices productivate
                                                        standardized convergence...
                                                    </p>
                                                    <span className='btn-link text-decoration-none read-more-link'>
                                                        Read More{' '}
                                                        <i className='far fa-arrow-right ms-2'></i>
                                                    </span>
                                                </Link>
                                            </li>
                                            <li className='py-4 border-top border-light'>
                                                <Link
                                                    to='support-single.html'
                                                    className='text-decoration-none d-block text-muted'
                                                >
                                                    <h3 className='h5 support-article-title'>
                                                        When is the upcoming annual event?
                                                    </h3>
                                                    <p>
                                                        Intrinsicly evisculate e-business best practices
                                                        productivate standardized convergence. Rapidiously
                                                        incubate alternative infrastructures with high
                                                        standards in e-markets...
                                                    </p>
                                                    <span className='btn-link text-decoration-none read-more-link'>
                                                        Read More{' '}
                                                        <i className='far fa-arrow-right ms-2'></i>
                                                    </span>
                                                </Link>
                                            </li>
                                            <li className='py-4 border-top border-light'>
                                                <Link
                                                    to='support-single.html'
                                                    className='text-decoration-none d-block text-muted'
                                                >
                                                    <h3 className='h5 support-article-title'>
                                                        What is the monthly cost of your app?
                                                    </h3>
                                                    <p>
                                                        A lternative infrastructures with high standards in
                                                        e-markets. Intrinsicly evisculate e-business best
                                                        practices productivate standardized convergence...
                                                    </p>
                                                    <span className='btn-link text-decoration-none read-more-link'>
                                                        Read More{' '}
                                                        <i className='far fa-arrow-right ms-2'></i>
                                                    </span>
                                                </Link>
                                            </li>
                                            <li className='py-4 border-top border-light'>
                                                <Link
                                                    to='support-single.html'
                                                    className='text-decoration-none d-block text-muted'
                                                >
                                                    <h3 className='h5 support-article-title'>
                                                        Do you offer refunds for the subscriptions?
                                                    </h3>
                                                    <p>
                                                        Mission-critical products before quality e-services.
                                                        Rapidiously incubate alternative infrastructures
                                                        with high standards e-business best practices
                                                        standardized convergence...
                                                    </p>
                                                    <span className='btn-link text-decoration-none read-more-link'>
                                                        Read More{' '}
                                                        <i className='far fa-arrow-right ms-2'></i>
                                                    </span>
                                                </Link>
                                            </li>
                                            <li className='py-4 border-top border-light'>
                                                <Link
                                                    to='support-single.html'
                                                    className='text-decoration-none d-block text-muted'
                                                >
                                                    <h3 className='h5 support-article-title'>
                                                        What’s inside the Facebook community?
                                                    </h3>
                                                    <p>
                                                        Credibly evisculate mission-critical products before
                                                        quality e-services. Rapidiously incubate alternative
                                                        infrastructures with high standards in e-markets...
                                                    </p>
                                                    <span className='btn-link text-decoration-none read-more-link'>
                                                        Read More{' '}
                                                        <i className='far fa-arrow-right ms-2'></i>
                                                    </span>
                                                </Link>
                                            </li>
                                            <li className='py-4 border-top border-light'>
                                                <Link
                                                    to='support-single.html'
                                                    className='text-decoration-none d-block text-muted'
                                                >
                                                    <h3 className='h5 support-article-title'>
                                                        How often is there a subscribers stream?
                                                    </h3>
                                                    <p>
                                                        Critical products before quality e-services.
                                                        Incubate alternative infrastructures with high
                                                        standards in intrinsicly evisculate e-business best
                                                        alternative infrastructures practices...
                                                    </p>
                                                    <span className='btn-link text-decoration-none read-more-link'>
                                                        Read More{' '}
                                                        <i className='far fa-arrow-right ms-2'></i>
                                                    </span>
                                                </Link>
                                            </li>
                                            <li className='py-4 border-top border-light'>
                                                <Link
                                                    to='support-single.html'
                                                    className='text-decoration-none d-block text-muted'
                                                >
                                                    <h3 className='h5 support-article-title'>
                                                        What certifications does Agency has?
                                                    </h3>
                                                    <p>
                                                        Credibly evisculate mission-critical ncubate
                                                        alternative infrastructures with high standards in
                                                        e-markets. e-business best practices productivate
                                                        standardized convergence...
                                                    </p>
                                                    <span className='btn-link text-decoration-none read-more-link'>
                                                        Read More{' '}
                                                        <i className='far fa-arrow-right ms-2'></i>
                                                    </span>
                                                </Link>
                                            </li>
                                        </ul> */}
                                    </div>
                                </div>
                                <div
                                    className='tab-pane fade'
                                    id='support-tab-2'
                                    role='tabpanel'
                                >
                                    <div className='support-article-wrap'>
                                        <h2>Orders</h2>
                                        <OrderTable userOrder={userOrder} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

const data = [
    {
        key: '1',
        name: 'John Brown',
        age: 32,
        address: 'New York No. 1 Lake Park',
    },
    {
        key: '2',
        name: 'Jim Green',
        age: 42,
        address: 'London No. 1 Lake Park',
    },
    {
        key: '3',
        name: 'Joe Black',
        age: 32,
        address: 'Sydney No. 1 Lake Park',
    },
    {
        key: '4',
        name: 'Jim Red',
        age: 32,
        address: 'London No. 2 Lake Park',
    },
];
const OrderTable = () => {
    const { userOrder } = useContext(adminContext);
    // console.log(userOrder, 'userOrder')
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});

    // useEffect(() => {
    //     // Sort the user orders by createdAt in descending order
    //     userOrder.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    // }, [userOrder]);

    const handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };
    const clearFilters = () => {
        setFilteredInfo({});
    };
    const clearAll = () => {
        setFilteredInfo({});
        setSortedInfo({});
    };
    const setAgeSort = () => {
        setSortedInfo({
            order: 'descend',
            columnKey: 'age',
        });
    };
    const columns = [
        // {
        //     title: 'Category Name',
        //     dataIndex: 'category_name'
        // },
        // {
        //     title: 'Service Name',
        //     dataIndex: 'service_name'
        // },
        {
            title: 'Package Name',
            dataIndex: 'package_name',
            // key: 'name',
            // filters: [
            //     {
            //         text: 'Joe',
            //         value: 'Joe',
            //     },
            //     {
            //         text: 'Jim',
            //         value: 'Jim',
            //     },
            // ],
            // filteredValue: filteredInfo.name || null,
            // onFilter: (value, record) => record.name.includes(value),
            // sorter: (a, b) => a.name.length - b.name.length,
            // sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
            // ellipsis: true,
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
        },
        // {
        //     title: 'social_link',
        //     dataIndex: 'social_link',
        // },
        {
            title: 'Status',
            dataIndex: 'status',
        },
        {
            title: 'View More',
            fixed: 'right',
            width: 90,
            render: (_, record) => (
                <>
                    <Button type="link" onClick={() => handleView(record)} icon={<EyeOutlined />} />
                </>
            ),
        },
    ];

    const [modalVisible, setModalVisible] = useState(false);
    const [recordInView, setRecordInView] = useState(null);

    const handleView = (record) => {
        setRecordInView(record);
        setModalVisible(true);
    };

    const handleCloseModal = () => {
        setModalVisible(false);
        setRecordInView(null);
    };

    return (
        <>
            <Space
                style={{
                    marginBottom: 16,
                }}
            >
                {/* <Button onClick={setAgeSort}>Sort age</Button>
                <Button onClick={clearFilters}>Clear filters</Button>
                <Button onClick={clearAll}>Clear filters and sorters</Button> */}
            </Space>
            <Table columns={columns} dataSource={userOrder} onChange={handleChange} />
            <Modal
                title="Order Details:"
                visible={modalVisible}
                onCancel={handleCloseModal}
                footer={null}
            >
                {recordInView && (
                    <>
                        <p><strong>Order Id:</strong><br /> {recordInView._id}</p>
                        <p><strong>Category Name:</strong> <br /> {recordInView.category_name}</p>
                        <p><strong>Service Name:</strong><br />  {recordInView.service_name}</p>
                        <p><strong>Package Name:</strong><br />  {recordInView.package_name}</p>
                        <p><strong>Social Link:</strong><br />  {recordInView.social_link}</p>
                        <p><strong>Total Charge :</strong><br />  {recordInView.totalCharge}</p>
                        <p><strong>Your Plan :</strong><br />  {recordInView.orderPlan ? recordInView.orderPlan : "Not Selected"}</p>
                        <p><strong>Status :</strong><br />  {recordInView.status}</p>
                        <p><strong>Created At:</strong><br />  {__formatDate(recordInView.createdAt)}</p>
                        <p><strong>Updated At:</strong><br />  {__formatDate(recordInView.updatedAt)}</p>
                    </>
                )}
            </Modal >
        </>
    );
};

function OrderTable2({ orders }) {
    return (
        <table className="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">User ID</th>
                    <th scope="col">Category</th>
                    <th scope="col">Service</th>
                    <th scope="col">Package</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Social Link</th>
                    <th scope="col">Total Charge</th>
                    <th scope="col">Status</th>
                    <th scope="col">Paid At</th>
                    <th scope="col">Created At</th>
                    <th scope="col">Updated At</th>
                </tr>
            </thead>
            <tbody>
                {orders?.map((order, index) => (
                    <tr key={order._id}>
                        <th scope="row">{index + 1}</th>
                        <td>{order.userId}</td>
                        <td>{order.category_name}</td>
                        <td>{order.service_name}</td>
                        <td>{order.package_name}</td>
                        <td>{order.quantity}</td>
                        <td>{order.social_link}</td>
                        <td>{order.totalCharge}</td>
                        <td>{order.status}</td>
                        <td>{order.paidAt}</td>
                        <td>{order.createdAt}</td>
                        <td>{order.updatedAt}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

// export default UserView;
