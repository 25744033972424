import React from 'react';
import { Link } from 'react-router-dom';
import Rating from '../review/Rating';

const ReviewTab = () => {
  return (
    <>
      <div className='price-feature-col pricing-feature-info text-white left-radius p-5 order-1 order-lg-0'>
        <Link to='/' className='mb-5 d-none d-xl-block d-lg-block'>
          <img
            src='assets/img/smm-logo/snapBooster.png'
            alt='logo'
            className='img-fluid'
          />
        </Link>
        <div className='customer-testimonial-wrap mt-60'>
          <div className='tab-content' id='nav-tabContent'>
            <div
              className='tab-pane fade show active'
              id='testimonial-tab-1'
              role='tabpanel'
            >
              <div className='testimonial-tab-content mb-4'>
                <blockquote>
                  <h5>Excellent Service for Boosting Social Media Presence!</h5>
                  SnapBooster has significantly improved my social media engagement. Their services are reliable and effective.
                </blockquote>
                <div className='author-info mt-4'>
                  <h6 className='mb-0'>John Smith</h6>
                  <span className='small'>Social Media Influencer</span>
                </div>
              </div>
            </div>
            <div
              className='tab-pane fade'
              id='testimonial-tab-2'
              role='tabpanel'
            >
              <div className='testimonial-tab-content mb-4'>
                <blockquote>
                  <h5>Great Results and Quick Delivery!</h5>
                  SnapBooster helped me grow my social media presence rapidly. I'm impressed by their efficiency and quality of service.
                </blockquote>
                <div className='author-info mt-4'>
                  <h6 className='mb-0'>Emily Johnson</h6>
                  <span className='small'>Small Business Owner</span>
                </div>
              </div>
            </div>
            <div
              className='tab-pane fade'
              id='testimonial-tab-3'
              role='tabpanel'
            >
              <div className='testimonial-tab-content mb-4'>
                <blockquote>
                  <h5>Highly Recommended for Social Media Growth!</h5>
                  SnapBooster exceeded my expectations with their social media marketing services. I've seen a significant increase in followers and engagement.
                </blockquote>
                <div className='author-info mt-4'>
                  <h6 className='mb-0'>Alexandra Miller</h6>
                  <span className='small'>Digital Marketer</span>
                </div>
              </div>
            </div>
          </div>
          <ul
            className='nav testimonial-tab-list mt-5'
            id='nav-tab'
            role='tablist'
          >
            <li className='nav-item'>
              <a
                className='active'
                href='#testimonial-tab-1'
                data-bs-toggle='tab'
                data-bs-target='#testimonial-tab-1'
                role='tab'
                aria-selected='true'
              >
                <img
                  src='assets/img/testimonial/1.jpg'
                  className='img-fluid rounded-circle'
                  width='60'
                  alt='user'
                />
              </a>
            </li>
            <li className='nav-item'>
              <a
                href='#testimonial-tab-2'
                data-bs-toggle='tab'
                data-bs-target='#testimonial-tab-2'
                role='tab'
                aria-selected='false'
              >
                <img
                  src='assets/img/testimonial/2.jpg'
                  className='img-fluid rounded-circle'
                  width='60'
                  alt='user'
                />
              </a>
            </li>
            <li className='nav-item'>
              <a
                href='#testimonial-tab-3'
                data-bs-toggle='tab'
                data-bs-target='#testimonial-tab-3'
                role='tab'
                aria-selected='false'
              >
                <img
                  src='assets/img/testimonial/3.jpg'
                  className='img-fluid rounded-circle'
                  width='60'
                  alt='user'
                />
              </a>
            </li>
          </ul>
        </div>
        <div className='row justify-content-center mt-60'>
          <div className='col-12'>
            <p>Already trusted by some of the greatest companies</p>
            <ul className='list-unstyled list-inline mb-0'>
              <li className='list-inline-item'>
                <img
                  src='assets/img/clients/client-logo-1.svg'
                  width='120'
                  alt='clients logo'
                  className='img-fluid py-3 me-3 customer-logo'
                />
              </li>
              <li className='list-inline-item'>
                <img
                  src='assets/img/clients/client-logo-2.svg'
                  width='120'
                  alt='clients logo'
                  className='img-fluid py-3 me-3 customer-logo'
                />
              </li>
              <li className='list-inline-item'>
                <img
                  src='assets/img/clients/client-logo-3.svg'
                  width='120'
                  alt='clients logo'
                  className='img-fluid py-3 me-3 customer-logo'
                />
              </li>
              <li className='list-inline-item'>
                <img
                  src='assets/img/clients/client-logo-4.svg'
                  width='120'
                  alt='clients logo'
                  className='img-fluid py-3 me-3 customer-logo'
                />
              </li>
              <li className='list-inline-item'>
                <img
                  src='assets/img/clients/client-logo-5.svg'
                  width='120'
                  alt='clients logo'
                  className='img-fluid py-3 me-3 customer-logo'
                />
              </li>
              <li className='list-inline-item'>
                <img
                  src='assets/img/clients/client-logo-6.svg'
                  width='120'
                  alt='clients logo'
                  className='img-fluid py-3 me-3 customer-logo'
                />
              </li>
            </ul>
          </div>
        </div>
      </div>

    </>
  );
};

export default ReviewTab;
