import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import AdminContext from '../../context/adminContext';
const FooterOne = ({ footerLight, style, footerGradient }) => {

  const currentYear = new Date().getFullYear();
  const context = useContext(AdminContext);
  const { welcomeIntro, API_URL } = context;
  // console.log(welcomeIntro, "footer welcomeIntro")
  const welcomeData = welcomeIntro && welcomeIntro.length > 0 ? welcomeIntro[0] : null;
  // Destructuring welcomeIntro data
  const { logo, address, email, fbLink, instaLink, youtubeLink, mobile } = welcomeData || {};

  return (
    <>
      <footer className='footer-section'>
        <div
          className={`footer-top ${footerLight ? 'footer-light' : 'bg-dark'} ${footerGradient ? 'bg-gradient' : ''
            }  text-white ptb-120`}
          style={style}
        >
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='col-md-8 col-lg-4 mb-md-4 mb-lg-0'>
                <div className='footer-single-col'>
                  <div className='footer-single-col mb-4'>
                    <img
                      // src='assets/img/logo-white.png'
                      src="assets/img/smm-logo/snapBooster.png"
                      // src={`${API_URL}/${logo}`}
                      alt='logo'
                      className='img-fluid logo-white'
                    />
                    <img
                      // src='assets/img/logo-color.png'
                      src="assets/img/smm-logo/snapBooster.png"
                      // src={`${API_URL}/${logo}`}

                      alt='logo'
                      className='img-fluid logo-color'
                    />
                  </div>
                  <p>
                    We have extensive Experience in SocialMedia Marketing
                  </p>
                  {/* <form className='newsletter-form position-relative d-block d-lg-flex d-md-flex'>
                    <input
                      type='text'
                      className='input-newsletter form-control me-2'
                      placeholder='Enter your email'
                      name='email'
                      required=''
                      autoComplete='off'
                    />
                    <input
                      type='submit'
                      value='Subscribe'
                      data-wait='Please wait...'
                      className='btn btn-primary mt-3 mt-lg-0 mt-md-0'
                    />
                  </form> */}
                  <div className='ratting-wrap mt-4'>
                    <h6 className='mb-0'>10/10 Overall rating</h6>
                    <ul className='list-unstyled rating-list list-inline mb-0'>
                      <li className='list-inline-item'>
                        <i className='fas fa-star text-warning'></i>
                      </li>
                      <li className='list-inline-item'>
                        <i className='fas fa-star text-warning'></i>
                      </li>
                      <li className='list-inline-item'>
                        <i className='fas fa-star text-warning'></i>
                      </li>
                      <li className='list-inline-item'>
                        <i className='fas fa-star text-warning'></i>
                      </li>
                      <li className='list-inline-item'>
                        <i className='fas fa-star text-warning'></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='col-md-12 col-lg-7 mt-4 mt-md-0 mt-lg-0'>
                <div className='row'>
                  <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <h3>Primary Pages</h3>
                      <ul className='list-unstyled footer-nav-list mb-lg-0'>
                        <li>
                          <Link to='/' className='text-decoration-none'>
                            Home
                          </Link>
                        </li>
                        <li>
                          <Link
                            // to='/about-us' 
                            to='/contact-us'
                            className='text-decoration-none'>
                            About Us
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/contact-us'
                            className='text-decoration-none'>
                            Contact Us
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/faq'
                            className='text-decoration-none'>
                            FAQ
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <h3>Contact Information</h3>
                      <ul className='list-unstyled footer-contact-info mb-lg-0'>
                        <li className='mt-2'>
                          <strong>Address:</strong> {address || "108, Delhi NCR, 108, Delhi NCR, 108, Delhi NCR, 1234567890"}
                        </li>
                        <li className='mt-2'>
                          <a
                            href={`mailto:${email}`}
                            className='read-more-link text-decoration-none text-white'
                          >
                            <i className='far fa-envelope me-2'></i> {email}
                          </a>
                        </li>
                        <li className='mt-2'>
                          <a
                            href={`tel:${mobile}`}
                            className='read-more-link text-decoration-none text-white'
                          >
                            <i className='far fa-phone me-2'></i> {mobile}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  {/* <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <h3>Pages</h3>
                      <ul className='list-unstyled footer-nav-list mb-lg-0'>
                        <li>
                          <Link to='/pricing' className='text-decoration-none'>
                            Pricing
                          </Link>
                        </li>
                        <li>
                          <Link to='/blogs' className='text-decoration-none'>
                            Blog
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                  {/* <div className='col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <h3>Template</h3>
                      <ul className='list-unstyled footer-nav-list mb-lg-0'>
                        <li>
                          <Link
                            // to='/contact-us'
                            className='text-decoration-none'
                          >
                            Contact
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`footer-bottom ${footerLight ? 'footer-light' : 'bg-dark'
            } ${footerGradient ? 'bg-gradient' : ''} text-white py-4`}
        >
          <div className='container'>
            <div className='row justify-content-between align-items-center'>
              <div className='col-md-7 col-lg-7'>
                <div className='copyright-text'>
                  <p className='mb-lg-0 mb-md-0'>
                    &copy; {currentYear} SnapBooster {' '}
                    {/* <Link
                      to='/https://google.com/'
                      className='text-decoration-none ms-2'
                    >
                      Website Name
                    </Link> */}
                  </p>
                </div>
              </div>
              <div className='col-md-4 col-lg-4'>
                <div className='footer-single-col text-start text-lg-end text-md-end'>
                  <ul className='list-unstyled list-inline footer-social-list mb-0'>
                    <li className='list-inline-item'>
                      <Link to={fbLink} target='_blank' rel='noopener noreferrer'
                      >
                        <i className='fab fa-facebook-f'></i>
                      </Link>
                    </li>
                    <li className='list-inline-item'>
                      <Link to={instaLink} target='_blank' rel='noopener noreferrer'>
                        <i className='fab fa-instagram'></i>
                      </Link>
                    </li>
                    <li className='list-inline-item'>
                      <Link to={youtubeLink} target='_blank' rel='noopener noreferrer'>
                        <i className='fab fa-youtube'></i>
                      </Link>
                    </li>
                    {/* <li className='list-inline-item'>
                      <Link to='/#'>
                        <i className='fab fa-dribbble'></i>
                      </Link>
                    </li>
                    <li className='list-inline-item'>
                      <Link to='/#'>
                        <i className='fab fa-github'></i>
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterOne;
