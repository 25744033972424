import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AdminContext from '../../context/adminContext';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';
import { Spin } from 'antd';

const QuickOrder = () => {

    const context = useContext(AdminContext);
    const { user, host, services, categories, GetAllServices, GetAllCategories, API_URL, GetAllPackages, packages } = context;
    // const [selectedCategory, setSelectedCategory] = useState([]);
    // const [selectedService, setSelectedService] = useState([]);
    // const [selectedPackage, setSelectedPackage] = useState([]);
    // const [packageAmount, setPackageAmount] = useState('');
    const [showMore, setShowMore] = useState(false);
    const toggleDescription = () => {
        setShowMore(!showMore);
    };
    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    // const packageAmountInINR = packageAmount.toLocaleString('en-IN', {
    //     style: 'currency',
    //     currency: 'INR'
    // });

    const [selectedService, setSelectedService] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedPackage, setSelectedPackage] = useState('');

    // const filteredServices = services.filter((service) => service.category === selectedCategory);
    const filteredServices = services.filter((service) => service.category?._id === selectedCategory);
    // console.log(filteredServices, 'filteredServices')
    const filteredPackages = packages.filter((pkg) => pkg.service?._id === selectedService);
    // console.log(filteredPackages, 'filteredPackages')
    const extractedPackage = filteredPackages?.map(item => ({
        packageName: item.packageName,
        packageTask: item.packageTask,
        packageDays: item.packageDays,
        packageAmount: item.packageAmount,
    }));
    // console.log(extractedPackage);

    const [state, setState] = useState({
        isLoading: false,
        category: '',
        service: '',
        packageName: '',
        social_link: '',
        quantity: null,
        pricePerAction: '',
        // packageAmount: filteredPackage?.packageAmount || '',
        packageAmount: null,
        totalCharge: 0,
        orderPlan: ''
    });

    const { isLoading, category, service, packageName, social_link, quantity, pricePerAction, packageAmount, totalCharge, orderPlan } = state;

    const updateState = data => setState(state => ({ ...state, ...data }))
    // console.log(state, "updateState")
    // const fetchData = () => {
    //     // GetAllCategories();
    //     // GetAllServices();
    //     // GetAllPackages();
    // };
    // useEffect(() => {
    //     fetchData();
    // }, []);

    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
        const selectedCategoryValue = e.target.value;
        setSelectedCategory(selectedCategoryValue);
        // reset previous pakage values
        setSelectedPackage('')
        updateState({ category: selectedCategoryValue });
        updateState({
            packageName: '', packageAmount: '',
            quantity: 0, totalCharge: 0,
            pricePerAction: '',
        })
    };

    const handleServiceChange = (e) => {
        setSelectedService(e.target.value);
        const selectedServiceValue = e.target.value;
        updateState({ service: selectedServiceValue });
    };

    const handlePackageChange = (e) => {
        setSelectedPackage(e.target.value);
        const selectedPackageValue = e.target.value;
        // updateState({ quantity: 0, totalCharge: 0, packageName: selectedPackageValue });
        const selectedPackage = packages.find(pkg => pkg._id === selectedPackageValue);
        // console.log(selectedPackage, 'selectedPackage')
        setSelectedPackage(selectedPackageValue);
        // updateState({ packageName: selectedPackage.packageName, packageAmount: selectedPackage.packageAmount });
        updateState({
            packageName: selectedPackage.packageName, packageAmount: selectedPackage.packageAmount,
            quantity: 1,
            // totalCharge: selectedPackage.packageAmount.toLocaleString('en-IN', {
            //     style: 'currency',
            //     currency: 'INR'
            // }),
            totalCharge: selectedPackage.packageAmount.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
            }),
            pricePerAction: selectedPackage.packageAmount
        })
        // if (selectedPackage) {
        //     setSelectedPackage(selectedPackageValue);
        //     updateState({ packageName: selectedPackage.packageName, packageAmount: selectedPackage.packageAmount });
        // }
    };

    const handelQuantityChange = (event) => {
        const newQuantity = parseInt(event.target.value);
        const newTotalCharge = newQuantity * packageAmount;
        updateState({
            quantity: newQuantity,
            totalCharge: newTotalCharge.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
            })
        });
    };
    const UserData = user
    // console.log(UserData)
    const UserId = user?._id

    const handelSubmitOrder = async (e) => {
        e.preventDefault();
        // const isValid = validateForm();
        if (isChecked) {
            updateState({ isLoading: true });
            const FilteredCategory = packages.filter(c => c.category._id === category)
            const FilteredService = packages.filter(s => s.service._id === service)
            console.log(FilteredCategory, 'FilteredCategory')
            // console.log(FilteredCategory?.category?.categoryName, 'FilteredCategory')
            // console.log(FilteredService?.service?.serviceName, 'FilteredService')
            const categoryNames = FilteredCategory?.map(item => item?.category.categoryName);
            const serviceNames = FilteredService?.map(item => item?.service.serviceName);
            // console.log(categoryNames[0], 'categoryNames')
            // console.log(serviceNames[0], 'serviceNames')
            try {
                const formData = new FormData();
                formData.append('user', JSON.stringify(UserData));
                formData.append('userId', UserId);
                formData.append('category_name', categoryNames[0]);
                formData.append('service_name', serviceNames[0]);
                formData.append('package_name', packageName);
                formData.append('quantity', quantity);
                formData.append('social_link', social_link);
                formData.append('totalCharge', totalCharge);
                formData.append('orderPlan', orderPlan);
                const url = `${host}/orders/create-order`;
                const response = await axios({
                    method: 'post',
                    url,
                    data: formData,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                // console.log(response, 'response')
                if (response.data.success == true) {
                    updateState({
                        isLoading: false,
                        category: '',
                        service: '',
                        packageName: '',
                        social_link: '',
                        quantity: null,
                        packageAmount: null,
                        totalCharge: null,
                    })
                    toast("Service Order Successfully!", {
                        type: "success",
                    });
                    window.location.href = '/user-profile'
                } else {
                    updateState({ isLoading: false });
                    alert("Got error on ordering: " + response.data)
                    toast("Failed ", {
                        type: "error",
                    });
                }
            } catch (error) {
                updateState({ isLoading: false });
                console.error('Error on Ordering :', error);
                toast("Error:", {
                    type: "error",
                });
            }
        } else {
            // If checkbox is not checked, show an alert or do nothing
            alert('Please check the checkbox to proceed.');
        }
    };

    const orderPlans = [

        {
            "id": 1,
            "name": "One-Time Order",
            "value": "One-Time",
            "description": "Order will be placed only once"
        },
        {
            "id": 2,
            "name": "Order Every Seven Days",
            "value": "Every-Seven-Days",
            "description": "Order will be placed automatically every seven days"
        },
        {
            "id": 3,
            "name": "Order Every Thirty Days",
            "value": "Every-Thirty-Days",
            "description": "Order will be placed automatically every thirty days"
        }
    ]


    // useEffect(() => {
    //     if (selectedPackage) {
    //         const packageData = packages.find((pkg) => pkg._id === selectedPackage);
    //         if (packageData) {
    //             setPackageAmount(packageData.packageAmount);
    //         }
    //     }
    // }, [selectedPackage, packages]);

    // const [selectedServiceData, setSelectedServiceData] = useState(null);

    // console.log(selectedServiceData, 'selectedPackageData')
    // useEffect(() => {
    //     if (selectedPackage) {
    //         const packageData = packages.find((pkg) => pkg._id === selectedPackage);
    //         if (packageData) {
    //             setSelectedServiceData(packageData);
    //         }
    //     }
    // }, [selectedPackage, packages]);


    return (
        <>
            <div className='col-xl-5 col-lg-7 col-md-12 order-0 order-lg-1'>
                <div className='register-wrap p-5 bg-white shadow rounded-custom mt-5 mt-lg-0 mt-xl-0'>
                    <h3 className='fw-medium h4 text-center'>
                        Quick Order
                    </h3>
                    <form
                        onSubmit={handelSubmitOrder}
                        className='mt-4 register-form'
                    >
                        <div className='row'>
                            <div className='col-sm-6'>
                                <label htmlFor='choosecategory' className='mb-1'>
                                    Choose Category<span className='text-danger'>*</span>
                                </label>
                                <div className='input-group mb-3'>
                                    <select
                                        className='form-control form-select rounded-pill  '
                                        name="category"
                                        type="select"
                                        id='choosecategory'
                                        required
                                        data-msg='Chose Social Media.'
                                        value={selectedCategory}
                                        // onChange={(e) => {
                                        //     console.log(e.target.value)
                                        //     handleCategorySelect(e.target.value)
                                        // }}
                                        onChange={handleCategoryChange}
                                    >
                                        <option value="" disabled>Select a service</option>
                                        {categories?.map(categories => (
                                            <option key={categories._id} value={categories._id}>
                                                {categories?.categoryName}
                                            </option>
                                        ))}
                                        {/* {selectedService?.map(selectedService => (
                                            <option key={selectedService._id} value={selectedService._id}>
                                                {selectedService?.categoryName}
                                            </option>
                                        ))} */}
                                    </select>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <label htmlFor='serviceSelect' className='mb-1'>
                                    Choose Service. <span className='text-danger'>*</span>
                                </label>
                                <div className='input-group mb-3'>
                                    <select
                                        className='form-control form-select rounded-pill  '
                                        name='service'
                                        id='serviceSelect'
                                        required
                                        data-msg='Choose Type.'
                                        value={selectedService}
                                        // onChange={(e) => {
                                        //     console.log(e.target.value)
                                        //     handleServiceSelect(e.target.value)
                                        // }}
                                        onChange={handleServiceChange}
                                    >
                                        <option value="" disabled>Select a service</option>
                                        {filteredServices?.map(services => (
                                            <option key={services._id} value={services._id}>
                                                {services?.serviceName}
                                            </option>
                                        ))}
                                        {/* {services?.map(services => (
                                            <option key={services._id} value={services._id}>
                                                {services?.serviceName}
                                            </option>
                                        ))} */}
                                    </select>
                                </div>
                            </div>
                            <div className='col-lg-12'>
                                <label htmlFor='selectpackage' className='mb-1'>
                                    Choose Package <span className='text-danger'>*</span>
                                    <span className='text-primary mx-2'>Price / 1: &nbsp;
                                        {pricePerAction.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD'
                                        })}
                                    </span>
                                </label>
                                <div className='input-group mb-3'>
                                    <select
                                        className='form-control form-select rounded-pill  '
                                        name='package'
                                        id='selectpackage'
                                        required
                                        data-msg='Select Package.'
                                        onChange={handlePackageChange}
                                    >
                                        <option value="" disabled>Select Package</option>
                                        {filteredPackages?.map(packages => (
                                            <option key={packages._id} value={packages._id}>
                                                {packages?.packageName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='col-12'>
                                <label htmlFor='social-link' className='mb-1'>
                                    Social Media Link . <span className='text-danger'>*</span>
                                </label>
                                <div className='input-group mb-3'>
                                    <input
                                        id='social-link '
                                        className='form-control rounded-pill  '
                                        placeholder='Paste Your Social Media Account  Link Here'
                                        required
                                        style={{ height: '60px' }}
                                        value={social_link}
                                        name='social_link'
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            updateState({ social_link: selectedValue });
                                        }}
                                    >
                                    </input>
                                </div>
                                <p className="text-muted">Enter the URL of your social media account.</p>

                            </div>

                            <div className='col-sm-6'>
                                <label className='mb-1'>
                                    Quantity:
                                    {/* <span className='text-danger'>*</span> */}
                                </label>
                                {/* <div className='input-group mb-3 form-control rounded-pill'>
                                        Charge :<div className=''> {FinalAmount}</div>
                                    </div> */}
                                <input
                                    className='input-group mb-3 form-control rounded-pill text-center'
                                    type="number"
                                    value={quantity}
                                    required
                                    // onChange={(e) => {
                                    //     updateState(
                                    //         { quantity: e.target.value }
                                    //     );
                                    // }}
                                    onChange={handelQuantityChange}
                                // readOnly
                                />
                            </div>
                            <div className='col-sm-6'>
                                <label className='mb-1'>
                                    Charge:
                                    {/* <span className='text-danger'>*</span> */}
                                </label>
                                {/* <div className='input-group mb-3 form-control rounded-pill '>
                                        &nbsp;&nbsp; Charge : &nbsp;<div className='text-primary text-bold'> final ampunt</div>
                                    </div> */}
                                <input
                                    className='input-group mb-3 form-control text center  rounded-pill'
                                    type="text"
                                    value={totalCharge}
                                    readOnly
                                />
                            </div>
                            <div className='col-sm-12'>
                                <label className='mb-1'>Choose a Plan:</label>
                                {orderPlans?.map((plan) => (
                                    <div className='form-check' key={plan.id}>
                                        <input
                                            className='form-check-input'
                                            type='radio'
                                            name='orderPlan'
                                            required
                                            id={plan.value}
                                            value={plan.value}
                                            // onChange={handleOrderPlanChange}
                                            onChange={(e) => {
                                                const selectedValue = e.target.value;
                                                console.log(selectedValue, "orderPlan")
                                                updateState({ orderPlan: selectedValue });
                                            }}
                                        />
                                        <label className='form-check-label' htmlFor={plan.value}>
                                            {plan.name}
                                            <span className="text-muted"> ({plan.description})</span>
                                        </label>
                                    </div>
                                ))}
                            </div>
                            <div className='col-12'>
                                <div className='form-check'>
                                    <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexCheckChecked'
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label
                                        className='form-check-label small'
                                        htmlFor='flexCheckChecked'
                                    >
                                        Yes, I'd like to receive occasional marketing emails from
                                        us. I have the right to opt out at any time.
                                        {showMore && (
                                            <>
                                                {extractedPackage?.length !== 0 && (
                                                    <>
                                                        <hr />
                                                        {/* <p> Package Name: {extractedPackage[0].packageName}</p> */}
                                                        <p> Package Days: {extractedPackage[0].packageDays}</p>
                                                        <p> Package Task: {extractedPackage[0].packageTask}</p>
                                                        <hr />
                                                    </>
                                                )}
                                                <br />
                                                Please ensure your username and social media link are accurate for a seamless experience.
                                                We take every step to ensure secure transactions.
                                                We will not be responsible for payment failures.
                                                <Link className="btn-link" to='#'> View privacy policy</Link>.
                                                <button className="btn btn-link p-0" onClick={toggleDescription}>Show Less</button>
                                            </>
                                        )}
                                        {!showMore && (
                                            <>
                                                <button className="btn btn-link p-0" onClick={toggleDescription}>Show More</button>
                                            </>
                                        )}
                                    </label>
                                </div>
                            </div>
                            <div className='col-12'>
                                {localStorage.getItem("token") ? (
                                    isLoading ? (
                                        <button
                                            className='btn btn rounded-pill mt-4 d-block w-100'
                                        >
                                            <LoadingSpinner />
                                        </button>
                                    ) : (
                                        <button
                                            type='submit'
                                            className='btn btn-primary rounded-pill mt-4 d-block w-100'
                                        >
                                            Order
                                        </button>
                                    )
                                ) : (
                                    <>
                                        <Link to="/login">
                                            <button
                                                type='submit'
                                                className='btn btn-primary mt-4 d-block w-100'
                                            >
                                                Please Login First
                                            </button>
                                        </Link>
                                    </>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default QuickOrder;
const LoadingSpinner = () => {
    return (
        <div className="container mt-5 text-center">
            <Spin size="large" />
        </div>
    );
};