import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video';
import adminContext from '../../context/adminContext';

export default function HeroFourteen() {
  const { welcomeIntro, API_URL } = useContext(adminContext);
  // useEffect(() => {
  //   GetHomePageData()
  // }, [])

  // const welcomeData = welcomeIntro?.WelcomeData[0];
  // console.log(welcomeIntro, 'welcomeIntro home 14')
  // Check if welcomeIntro is an array and has elements before destructuring
  const welcomeData = welcomeIntro && welcomeIntro.length > 0 ? welcomeIntro[0] : null;
  // Destructuring welcomeIntro data
  const { welcomeHeading, welcomeText, welcomeDec, youtubeUrl, image } = welcomeData || {};

  function extractYouTubeVideoId(url) {
    // Check if the url is undefined or null
    if (!url) {
      return null;
    }
    // Regular expression to match YouTube video IDs
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/]+\/[^/]+\/|(?:v|e(?:mbed)?)\/|[^#]*[?&]v=|youtu\.be\/)([a-zA-Z0-9_-]{11}))/;
    // Extract video ID using regex
    const match = url.match(regex);
    // Check if a match is found
    if (match && match[1]) {
      return match[1]; // Return the extracted video ID
    } else {
      return null; // Return null if no match is found
    }
  }
  // const youtubeUrl2 = 'https://www.youtube.com/watch?v=hAP2QF--2Dg';
  const videoId = extractYouTubeVideoId(youtubeUrl);
  console.warn(videoId, 'videoId')

  const [isOpen, setOpen] = useState(false);
  return (
    <section className="digi-hero ptb-120">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-6">
            <div className="digi-hero-text mt-5 mt-lg-0">
              <div>
                {/* <span className="span-arrow">{welcomeHeading || "Best Digital Agency"}</span> */}
                <span className="span-arrow" dangerouslySetInnerHTML={{ __html: welcomeHeading }}></span>
                {/* <img src="assets/img/arro-right.svg" alt="arrow" /> */}
              </div>
              {
                welcomeText ?
                  <>
                    <h1 className="display-5 fw-bold mb-3">
                      <div dangerouslySetInnerHTML={{ __html: welcomeText }} />
                    </h1>
                  </>
                  :
                  <>
                    <h1 className="display-5 fw-bold mb-3">
                      We Are the
                      <span className="text-orange fw-bold">Brilliants</span>
                      in Terms of digital Marketing
                    </h1>
                  </>
              }
              {/* <h1 className="display-5 fw-bold mb-3">
                We Are the
                <span className="text-orange fw-bold">Brilliants</span>
                in Terms of digital Marketing
              </h1> */}
              <p className="m-0" dangerouslySetInnerHTML={{ __html: welcomeDec }}>
                {/* {welcomeDec || 'End-to-end payments and financial management in a single solution. Meet the right platform to help realize.'} */}
              </p>
              <div className="action-btns mt-4 align-items-center d-block d-sm-flex d-lg-flex d-md-flex">
                <ModalVideo
                  channel="youtube"
                  isOpen={isOpen}
                  // videoId="hAP2QF--2Dg"
                  videoId={videoId}
                  onClose={() => setOpen(false)}
                />
                <Link
                  to="/signup"
                  className="btn rounded-pill bg-orange me-3"
                >
                  Connect With Us
                </Link>
                <Link
                  to="#!"
                  onClick={() => setOpen(true)}
                  className="text-decoration-none popup-youtube d-inline-flex align-items-center watch-now-btn mt-3 mt-lg-0 mt-md-0"
                >
                  <i className="fas fa-play"></i> How It Works
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-7 cool-md-6">
            <div className="digi-hero-img text-center position-relative mt-5">
              <img
                // src="assets/img/digi-h-girl.jpg"
                // src={`${API_URL}/${image}`}
                src={image ? `${API_URL}/${image}` : 'assets/img/digi-h-girl.jpg'}
                className="img-fluid"
                alt=""
              />
              <ul className="list-unstyled d-none d-xl-block">
                {/* <li>
                  <img
                    src="assets/img/digital-marketing/hero-shape-1.png"
                    alt="shape"
                    className=""
                  />
                </li> */}
                <li>
                  {/* <img
                    src="assets/img/digital-marketing/hero-shape-2.png"
                    alt="shape"
                  /> */}
                </li>
                {/* <li>
                  <img
                    src="assets/img/digital-marketing/hero-shape-3.png"
                    alt="shape"
                  />
                </li> */}
                {/* <li>
                  <img
                    src="assets/img/digital-marketing/hero-shape-4.png"
                    alt="shape"
                  />
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
