import React, { useState, useContext } from "react";
import { Link } from 'react-router-dom';
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import AdminContext from '../../context/adminContext';
import { useNavigate } from "react-router-dom";

const SignUpForm = () => {
  const context = useContext(AdminContext);
  const { Rigester } = context;
  let nevigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: '',
      username: '',
      password: '',
      confirm_password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("Please Enter Your Email"),
      username: Yup.string().min(2).max(25).required("Please Enter Your Username"),
      password: Yup.string().min(6).required("Please Enter Your Password"),
      confirm_password: Yup.string().required().oneOf([Yup.ref('password'), null], "Password Must Be Same"),
    }),
    onSubmit: async (values, action) => {
      // console.log(values)
      setIsSubmitting(true);
      let response = await Rigester(values)
      response && setIsSubmitting(false);
      response && nevigate('/login')
      // response ? alert('login') : alert('error')
      // response && nevigate('/auth-two-step-verification')
      action.resetForm();
    }
  });

  return (
    <>
      <div className='price-feature-col pricing-action-info p-5 right-radius bg-light order-0 order-lg-1'>
        <Link to='/' className='mb-5 d-block d-xl-none d-lg-none'>
          <img
            // src='assets/img/logo-color.png'
            src="assets/img/smm-logo/snapBooster.png"
            alt='logo'
            className='img-fluid'
          />
        </Link>
        <h1 className='h3'>Create an Account</h1>
        <p className='text-muted'>
          Get started with your free account today. No credit card needed and no
          setup fees.
        </p>

        <form
          onSubmit={(e) => {
            e.preventDefault();

            validation.handleSubmit();
            return false;
          }}
          className='mt-5 register-form'>
          <div className='row'>

            <div className='col-sm-12'>
              <label htmlFor='name' className='mb-1'>
                Name <span className='text-danger'>*</span>
              </label>
              <div className='input-group mb-3'>
                <input
                  className={`form-control ${validation.touched.username && validation.errors.username ? 'is-invalid' : ''}`}
                  id='name'
                  required
                  aria-label='name'
                  name="username"
                  type="text"
                  placeholder="Enter Your Name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.username || ""}
                />
                {validation.touched.username && validation.errors.username ? (
                  <div className='invalid-feedback'>{validation.errors.username}</div>
                ) : null}
              </div>
            </div>
            <div className='col-sm-12 '>
              <label htmlFor='email' className='mb-1'>
                Email <span className='text-danger'>*</span>
              </label>
              <div className='input-group mb-3'>
                <input
                  type='email'
                  name="email"
                  className={`form-control ${validation.touched.email && validation.errors.email ? 'is-invalid' : ''}`}
                  placeholder='Email'
                  id='email'
                  required
                  aria-label='email'
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                />
                {validation.touched.email && validation.errors.email ? (
                  <div className='invalid-feedback'
                  >{validation.errors.email}</div>
                ) : null}
              </div>
            </div>


            <div className='col-sm-12'>
              <label htmlFor='password' className='mb-1'>
                Password <span className='text-danger'>*</span>
              </label>
              <div className='input-group mb-3'>
                <input
                  className={`form-control ${validation.touched.password && validation.errors.password ? 'is-invalid' : ''}`}
                  id='password'
                  required
                  aria-label='Password'
                  name="password"
                  type="password"
                  placeholder="Enter Password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.password || ""}
                />
                {validation.touched.password && validation.errors.password ? (
                  <div className='invalid-feedback'>{validation.errors.password}</div>
                ) : null}
              </div>
            </div>
            <div className='col-sm-12'>
              <label htmlFor='cpassword' className='mb-1'>
                Confirm  Password <span className='text-danger'>*</span>
              </label>
              <div className='input-group mb-3'>
                <input
                  className={`form-control ${validation.touched.confirm_password && validation.errors.confirm_password ? 'is-invalid' : ''}`}
                  id='cpassword'
                  required
                  aria-label='Password'
                  name="confirm_password"
                  type="password"
                  placeholder="Confirm Password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.confirm_password || ""}
                />
                {validation.touched.confirm_password && validation.errors.confirm_password ? (
                  <div className='invalid-feedback'>{validation.errors.confirm_password}</div>
                ) : null}
              </div>
            </div>

            <div className='col-12'>
              <div className='form-check d-flex'>
                <input
                  className='form-check-input me-2'
                  type='checkbox'
                  value=''
                  id='flexCheckChecked'
                />
                <label className='form-check-label' htmlFor='flexCheckChecked'>
                  I have read and agree to the{' '}
                  <Link to='#' className='text-decoration-none'>
                    Terms & Conditions
                  </Link>
                </label>
              </div>
            </div>
            <div className='col-12'>
              <button
                type='submit'
                className='btn btn-primary mt-4 d-block w-100'
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </div>

          {/* <div className='position-relative d-flex align-items-center justify-content-center mt-4 py-4'>
            <span className='divider-bar'></span>
            <h6 className='position-absolute text-center divider-text bg-light mb-0'>
              Or
            </h6>
          </div>
          <div className='action-btns'>
            <Link
              to='#'
              className='btn google-btn mt-4 d-block bg-white shadow-sm d-flex align-items-center text-decoration-none justify-content-center'
            >
              <img
                src='assets/img/google-icon.svg'
                alt='google'
                className='me-3'
              />
              <span>Sign up with Google</span>
            </Link>
          </div> */}
          <p className='text-center text-muted mt-4 mb-0 fw-medium font-monospace'>
            Already have an account?{' '}
            <Link to='/login' className='text-decoration-none'>
              Sign in
            </Link>
          </p>
        </form>
      </div>
    </>
  );
};

export default SignUpForm;
