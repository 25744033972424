import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import AdminContext from '../../context/adminContext';
import { useNavigate } from "react-router-dom";
// import { GoogleLogin } from '@react-oauth/google';
import {
  // LoginSocialFacebook,
  LoginSocialGoogle,
} from "reactjs-social-login"
import {
  GoogleLoginButton,
  // FacebookLoginButton
} from "react-social-login-buttons";
import { createButton } from "react-social-login-buttons";
import { GoogleLogin } from 'react-google-login';

import axios from "axios"

function GoogleIcon() {
  return (
    <img
      src='assets/img/google-icon.svg'
      alt='google'
      className='me-3'
    />
  );
}

const LoginScreen = () => {
  const context = useContext(AdminContext);
  const { Login } = context;
  let nevigate = useNavigate();

  const [show, setShow] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: "khanzafran8349@gmail.com" || '',
      password: "123456" || '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async (values) => {
      // console.log("values", values)
      setIsSubmitting(true);
      let response = await Login(values)
      console.log("response", response)
      // response && setIsSubmitting(false);
      if (response) {
        setIsSubmitting(false)
      }
      // response ? alert('login') : alert('error')
      response && nevigate('/')
      window.location.reload(true);
    }
  });

  const config = {
    text: "Log in with Facebook",
    // icon: "facebook",
    icon: GoogleIcon,
    iconFormat: (name) => `fa fa-${name}`,
    // style: { background: "#3b5998" },
    style: {
      background: "#3b5998",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },
    activeStyle: { background: "#0B163F" },
  };
  /** My Facebook login button. */
  const MyFacebookLoginButton = createButton(config);

  const signupWithSocal = async data => {
    await axios
      // .post(`${host}/auth/signup-socal`, data)
      .post(`${process.env.REACT_APP_HOST}/user/signup-socal`, data)
      .then(response => {
        console.log("result", response)
        localStorage.setItem("token", response.data.authtoken)
        // if (response.data.user_status == "new") {
        //   navigate("/subscription-plans")
        // } else if (response.data.user_status == "old") {
        //   navigate("/related-keyword-search")
        // }
      })
      .catch(error => {
        console.log(error)
      })
  }
  const SocalRegister = async data => {
    console.log(data, 'data')
    // signupWithSocal(data)
  }
  const responseGoogle = (response) => {
    console.log(response);
  }

  return (
    <>
      <section
        className='sign-up-in-section bg-dark ptb-60'
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat right bottom",
        }}
      >
        <div className='container'>
          <div className='row align-items-center justify-content-center'>
            <div className='col-lg-5 col-md-8 col-12'>
              <Link to='/' className='mb-4 d-block text-center'>
                <img
                  // src='assets/img/logo-white.png'
                  src="assets/img/smm-logo/snapBooster.png"
                  alt='logo'
                  className='img-fluid'
                />
              </Link>
              <div className='register-wrap p-5 bg-light shadow rounded-custom'>
                <h1 className='h3'>Nice to Seeing You Again</h1>
                <p className='text-muted'>
                  Please log in to access your account web-enabled methods of
                  innovative niches.
                </p>
                {/* <LoginSocialGoogle
                  client_id={process.env.REACT_APP_GG_APP_ID}
                  scope="openid profile email"
                  discoveryDocs="claims_supported"
                  access_type="offline"
                  // onResolve={res => SocalRegister(res)}
                  // onReject={err => console.log("err", err)}
                  onResolve={({ provider, data }) => {
                    console.log(provider, data);
                  }}
                  onReject={(err) => {
                    console.log(err);
                  }}
                >
                  <div className='action-btns'>
                    <Link
                      to='#'
                      className='btn google-btn bg-white shadow-sm mt-4 d-block d-flex align-items-center text-decoration-none justify-content-center'
                    >
                      <img
                        src='assets/img/google-icon.svg'
                        alt='google'
                        className='me-3'
                      />
                      <span>Connect with Google</span>
                    </Link>
                  </div>
                </LoginSocialGoogle> */}

                {/* <LoginSocialGoogle
                  client_id={"client-id"}
                  scope="openid profile email"
                  discoveryDocs="claims_supported"
                  access_type="offline"
                  onResolve={({ provider, data }) => {
                    console.log(provider, data);
                  }}
                  onReject={(err) => {
                    console.log(err);
                  }}
                >
                  <GoogleLoginButton />
                </LoginSocialGoogle> */}
                {/* <GoogleLogin
                  client_id={process.env.REACT_APP_GG_APP_ID}
                  buttonText="Login"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={'single_host_origin'}
                /> */}
                {/* <MyFacebookLoginButton onClick={() => alert("Hello")} /> */}
                {/* <div className='position-relative d-flex align-items-center justify-content-center mt-4 py-4'>
                  <span className='divider-bar'></span>
                  <h6 className='position-absolute text-center divider-text bg-light mb-0'>
                    Or
                  </h6>
                </div> */}
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                  className='mt-4 register-form'
                >
                  <div className='row'>
                    <div className='col-sm-12 '>
                      <label htmlFor='email' className='mb-1'>
                        Email <span className='text-danger'>*</span>
                      </label>
                      <div className='input-group mb-3'>
                        <input
                          type='email'
                          name="email"
                          className={`form-control ${validation.touched.email && validation.errors.email ? 'is-invalid' : ''}`}
                          placeholder='Email'
                          id='email'
                          required
                          aria-label='email'
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <div className='invalid-feedback'
                          >{validation.errors.email}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className='col-sm-12'>
                      <label htmlFor='password' className='mb-1'>
                        Password <span className='text-danger'>*</span>
                      </label>
                      <div className='input-group mb-3'>
                        <input
                          className={`form-control ${validation.touched.password && validation.errors.password ? 'is-invalid' : ''}`}
                          id='password'
                          required
                          aria-label='Password'
                          name="password"
                          // type="password"
                          type={show ? "text" : "password"}
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                        />
                        <button onClick={() => setShow(!show)} className="btn btn-light " type="button" id="password-addon">
                          <i className={show ? 'fas fa-eye' : 'fas fa-eye-slash'}></i>
                          {/* <i class="fas fa-eye-slash"></i> */}
                        </button>
                        {validation.touched.password && validation.errors.password ? (
                          <div className='invalid-feedback'>{validation.errors.password}</div>
                        ) : null}
                      </div>
                    </div>

                    {/* <div className='col'>
                      <label htmlFor='email' className='mb-1'>
                        Email <span className='text-danger'>*</span>
                      </label>
                      <div className='input-group mb-3'>
                        <input
                          id='email'
                          required
                          aria-label='email'
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                      </div>
                    </div>
                    <div className='col-sm-12'>
                      <label htmlFor='password' className='mb-1'>
                        Password <span className='text-danger'>*</span>
                      </label>
                      <div className='input-group mb-3'>
                        <input
                          className='form-control'
                          id='password'
                          required
                          aria-label='Password'
                          name="password"
                          value={validation.values.password || ""}
                          type={show ? "text" : "password"}
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        <button onClick={() => setShow(!show)} className="btn btn-light " type="button" id="password-addon">
                          <i className="mdi mdi-eye-outline"></i>
                        </button>
                      </div>
                    </div> */}

                    <div className='col-12'>
                      <button
                        type='submit'
                        className='btn btn-primary mt-3 d-block w-100'
                      >
                        {isSubmitting ? 'Submitting...' : 'Submit'}

                      </button>
                    </div>
                  </div>
                  <p className='font-monospace fw-medium text-center text-muted mt-3 pt-4 mb-0'>
                    Don’t have an account?
                    <Link to='/signup' className='text-decoration-none'>
                      Sign up Today
                    </Link>
                    <br />
                    <Link to='/password-reset' className='text-decoration-none'>
                      Forgot password
                    </Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginScreen;
