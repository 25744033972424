import React, { useState, useContext, useEffect } from "react";
import "./services.css";
import { Link } from "react-router-dom";
import AdminContext from '../../context/adminContext';
import ServiceDropdown from "./serviceDropdown";

const ServicesCategory2 = () => {

    const context = useContext(AdminContext);
    const { categories, GetAllServices, GetAllCategories, } = context;
    useEffect(() => {
        GetAllCategories();
        GetAllServices();
    }, [])
    const [activeCategory, setActiveCategory] = useState(null);

    const handleCategoryClick = (categoryId) => {
        setActiveCategory(activeCategory === categoryId ? null : categoryId);
    };

    return (
        <div>
            <div
                className="panel-group our-services__accordion mt-2 mb-5"
                id="our-services__accordion"
            >
                <div className="container2">
                    <div className="services-background">
                        <div className="row mx-2">
                            <div className="col-12">
                                {/* <ServiceDropdown /> */}
                                <div className="our-services__panel-type panel-type">
                                    {/* <div className="panel-type__title mb-2">Social media</div> */}
                                    <div className="mb-4 text-center">
                                        <span className="panel-type__title span-arrow">Social Media</span>
                                        <img src="assets/img/arro-right.svg" alt="arrow" />
                                    </div>
                                    <div className="panel-type__line"></div>
                                </div>
                            </div>
                            {categories?.map((category) => <SocalBox category={category} key={category._id} />)}
                            {/* {categories?.map((category) => category.status === 'Active' && <SocalBox category={category} key={category._id} />)} */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const SocalBox = ({ category }) => {
    const context = useContext(AdminContext);
    const { API_URL, services } = context;
    const [selectedCategory, setSelectedCategory] = useState(null);
// const filteredServices = PackagesbyServiceId.find((pkg) => pkg.service._id === serviceId);

    const [state, setState] = useState({
        isShow: false
    })
    const { isShow } = state

    const updateState = (data) => setState((...prv) => ({ ...prv, ...data }))

    // const filteredServices = services.filter((service) => service.category === category._id && service.status === 'Active');
    const filteredServices = services.filter((service) => service.category._id === category._id);
    // console.log(filteredServices)
    // console.log(filteredServices, 'filteredServices')

    return <>
        <div className="col-12 col-lg-4" style={{ cursor: "pointer" }} onClick={() => updateState({ isShow: !isShow })} >
            <div className="our-services__panel-container">
                <div className="our-services__panel-heading">
                    <div className="our-services__panel-title">
                        <div
                            className="accordion-toggle our-services__accordion-link collapsed"
                        >
                            <div className="our-services__accordion-img" onClick={() => updateState({ isShow: !isShow })} style={{ cursor: "pointer" }}>
                                <img
                                    src={`${API_URL}/${category?.icon}`}
                                    loading="lazy"
                                    width="24"
                                    alt={category.categoryName}
                                    onClick={() => updateState({ isShow: !isShow })}
                                />
                            </div>
                            <span className="our-services__accordion-link-name" style={{ cursor: "pointer" }} onClick={() => updateState({ isShow: !isShow })}>
                                {category.categoryName}
                            </span>
                        </div>
                        <hr />
                        {isShow && <div style={{ minHeight: "100px" }}>
                            {filteredServices && filteredServices.length > 0 ? (
                                filteredServices.map((item, index) => (
                                    <Link key={index}
                                        to={`/serviceForm?id=${item._id}`}
                                        className="w-100">
                                        <div className="bg-light rounded-pill mx-3 px-3 mt-2 py-1 mb-3">
                                            <img
                                                src={`${API_URL}/${item?.serviceIcon}`}
                                                loading="lazy"
                                                width="24"
                                                alt={item.serviceIcon}
                                                className="mx-2"
                                            />
                                            {item.serviceName}
                                        </div>
                                    </Link>
                                ))
                            ) : (
                                <div className="text-center mt-2">No services available</div>
                            )}

                        </div>
                        }
                    </div>
                </div>
            </div >
        </div >
    </>
}

export default ServicesCategory2;
