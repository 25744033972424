import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import AdminContext from "../../context/adminContext";
import { Modal } from "antd";
const OffCanvasMenu = () => {
  const context = useContext(AdminContext);
  const { categories, API_URL } = context;
  const [selectedCategory, setSelectedCategory] = useState(null);
  // console.log(selectedCategory, 'selectedCategory')
  const handleCategoryHover = (category) => {
    setSelectedCategory(category);
  };

  const handleModalClose = () => {
    setSelectedCategory(null);
  };
  return (
    <>
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasWithBackdrop"
      >
        <div className="offcanvas-header d-flex align-items-center mt-4">
          <Link
            to="/"
            className="d-flex align-items-center mb-md-0 text-decoration-none"
          >
            <img
              // src="assets/img/logo-color.png"
              src="assets/img/smm-logo/snapBooster.png"
              alt="logo"
              className="img-fluid ps-2"
            />
          </Link>
          <button
            type="button"
            className="close-btn text-danger"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <i className="far fa-close"></i>
          </button>
        </div>
        <div className="offcanvas-body">
          <ul className="nav col-12 col-md-auto justify-content-center main-menu">
            <li>
              <Link to="/" className="nav-link">
                Home
              </Link>
            </li>
            <li>
              <Link to="/contact-us" className="nav-link">
                Contacts
              </Link>
            </li>
            <li>
              <Link to="/faq" className="nav-link">
                FAQ
              </Link>
            </li>
            {/* <li>
              <Link to="/pricing" className="nav-link">
                Pricing
              </Link>
            </li> */}
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Services
              </a>
              <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                <div className="dropdown-grid rounded-custom width-fullllll">
                  {categories?.map((category) => (
                    <div key={category._id} className="dropdown-grid-item">
                      {/* <img
                            src={`${API_URL}/${category?.icon}`}
                            loading="lazy"
                            width="24"
                            alt={category.categoryName}
                          // onClick={() => updateState({ isShow: !isShow })}
                          /> */}
                      {/* <h6 className="drop-title">{category.categoryName}</h6> */}
                      <Link
                        // to={`/serviceForm?id=${category._id}`}
                        className="dropdown-link"
                      >
                        <span
                          className="me-2"
                          // onMouseEnter={() => handleCategoryHover(category._id)}
                          onClick={() => handleCategoryHover(category._id)}
                        >
                          <img
                            src={`${API_URL}/${category?.icon}`}
                            loading="lazy"
                            width="24"
                            alt={category.categoryName}
                            // onClick={() => updateState({ isShow: !isShow })}
                            // onMouseEnter={() => handleCategoryHover(category._id)}
                            onClick={() => handleCategoryHover(category._id)}
                          />
                        </span>
                        <div
                          // onMouseEnter={() => handleCategoryHover(category._id)}
                          onClick={() => handleCategoryHover(category._id)}
                          className="drop-title"
                        >
                          {category.categoryName}
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </li>
            {localStorage.getItem("token") ? (
              <>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="/#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    User View
                  </a>
                  <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                    <div className="dropdown-grid rounded-custom ">
                      {/* {Items?.map((category) => (
                        <div key={category._id} className="dropdown-grid-item">
                          <Link
                            to={`/serviceForm?id=${category._id}`}
                            className="dropdown-link">
                            <span className="me-2">
                              <img
                                src={`${API_URL}/${category?.icon}`}
                                loading="lazy"
                                width="24"
                                alt={category.categoryName}
                              // onClick={() => updateState({ isShow: !isShow })}
                              />
                            </span>
                            <div className="drop-title">{category.categoryName}</div>
                          </Link>
                        </div>
                      ))} */}
                      <div className="dropdown-grid-item">
                        {/* <h6 className="drop-heading">Different Demos</h6> */}
                        <Link to="/user-profile" className="dropdown-link">
                          {/* <span className="demo-list bg-primary rounded text-white fw-bold">
                            1
                          </span> */}
                          <div className="dropdown-info">
                            <div className="drop-title">User Profile</div>
                            <div className="drop-title">
                              <p className="  text-success">
                                {localStorage.getItem("email")}
                              </p>
                            </div>
                            {/* <p>
                              It's for <strong>SaaS Software</strong> Company
                            </p> */}
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
              </>
            ) : (
              ""
            )}
          </ul>
          <div className="action-btns  ">
            {!localStorage.getItem("token") ? (
              <Link to="/login" className="btn btn-link text-decoration-none ">
                Sign In
              </Link>
            ) : (
              <Link
                to="/login"
                className="btn btn-link text-decoration-none "
                onClick={() => localStorage.removeItem("token")}
              >
                Logout
              </Link>
            )}
            {/* <Link to="/request-for-demo" className="btn btn-primary">
              Get Started
            </Link> */}
          </div>
        </div>
      </div>
      <ServiceModal
        visible={!!selectedCategory}
        // services={selectedCategory ? selectedCategory.services : []}
        services2={selectedCategory ? selectedCategory : []}
        closeModal={handleModalClose}
      />
    </>
  );
};

export default OffCanvasMenu;

const ServiceModal = ({ visible, services2, closeModal }) => {
  // console.log(visible, services2, closeModal)
  const context = useContext(AdminContext);
  const { services, API_URL } = context;
  // console.log(services2, 'services')
  const filteredServices2 = services?.filter(
    (service) => service?.category?._id === services2
  );
  // console.log(filteredServices2, 'filteredServices2')
  return (
    <Modal
      title="Services"
      visible={visible}
      onCancel={closeModal}
      footer={null}
      // className='mx-2'
    >
      <ul>
        {filteredServices2 && filteredServices2?.length > 0 ? (
          filteredServices2?.map((service) => (
            <>
              <Link key={service._id} to={`/serviceForm?id=${service._id}`}>
                <li>
                  <img
                    src={`${API_URL}/${service?.serviceIcon}`}
                    loading="lazy"
                    width="30"
                    alt={service?.serviceIcon}
                    className="me-2 mx-3"
                  />
                  <a
                    className="mx-2 mt-2 fs-4"
                    href={`/serviceForm?id=${service._id}`}
                  >
                    {service?.category?.categoryName} {service?.serviceName}
                  </a>
                </li>
              </Link>
            </>
          ))
        ) : (
          <div className="text-center">No services available</div>
        )}
      </ul>
      {/* {filteredServices.length === 0 ? (
        <p>No services available for this category.</p>
      ) : (
        <ul>
          {filteredServices.map(service => (
            <li key={service._id}>
              <img
                src={`${API_URL}/${service?.serviceIcon}`}
                loading="lazy"
                width="24"
                alt={service.serviceIcon}
                className="me-2 mx-3"
              />
              <a className='mx-2 mt-2' href={`/serviceForm?id=${service._id}`}>{service.serviceName}</a>
            </li>
          ))}
        </ul>
      )} */}
    </Modal>
  );
};
