import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import AdminState from "./context/AdminState";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <>
    <AdminState>
      <ToastContainer
        // position="top-center"
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <App />
    </AdminState>

  </>

);
