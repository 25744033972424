// import React from 'react';
import PageHeader from '../../components/common/PageHeader';
import PageMeta from '../../components/common/PageMeta';
import FeatureImgContentThree from '../../components/feature-img-content/FeatureImgContentThree';
import FeatureImgEight from '../../components/features/FeatureImgEight';
import RequestForDemo from '../../components/others/RequestForDemo';
import PromoThree from '../../components/promo/PromoThree';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AdminContext from '../../context/adminContext';
import axios from 'axios';
// import { _getApiData, _postApiData } from "../../utils/api/index"
// import ServiceForm from '../../components/smmForm/ServiceForm';
import ServicesCategory2 from '../../components/services/ServicesCategory2';
import { Spin } from 'antd';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';


const OrderService = () => {
    return (
        <Layout>
            <PageMeta title="Snap Booster" />
            <Navbar navDark />
            <ServiceForm />
            <div className='row mt-5'>
                <div className='col'>
                    <ServicesCategory2 />
                </div>
            </div>
            <FooterOne />
            {/* <PageHeader
                title="Instagram"
                desc="Instagram services"
            /> */}
            {/* <FeatureImgEight paddingTop />
            <FeatureImgContentThree /> */}
            {/* <PromoThree hasBg /> */}
            {/* <RequestForDemo /> */}
        </Layout>
    );
};

const ServiceForm = () => {
    const location = useLocation();
    const context = useContext(AdminContext);
    const { GetAllServices, GetAllCategories, API_URL, GetAllPackages, packages,
        host, GetPackagesByServiceId, PackagesbyServiceId, user, UserDetails } = context;
    let nevigate = useNavigate();

    const [showMore, setShowMore] = useState(false);
    const toggleDescription = () => {
        setShowMore(!showMore);
    };
    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    // const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedService, setSelectedService] = useState('');
    const [selectedPackage, setSelectedPackage] = useState('');
    // const [packageAmount, setPackageAmount] = useState('');
    // console.log(packages, 'packages')
    // console.log(categories)

    //   // Extract the 'id' from the URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const serviceId = urlParams.get('id');
    // console.log("PackagesbyServiceId", PackagesbyServiceId)

    const filteredPackage = PackagesbyServiceId.find((pkg) => pkg.service._id === serviceId);
    // console.log(filteredPackage)
    // console.log(filteredPackage?.category?.icon)
    // console.log(filteredPackage?.service?.serviceIcon)

    const fetchData = () => {
        // Extract the 'id' from the URL parameters
        // const urlParams = new URLSearchParams(window.location.search);
        const urlParams = new URLSearchParams(location.search);

        const serviceId = urlParams.get('id');
        GetPackagesByServiceId(serviceId)
        UserDetails()
    };

    // Call fetchData on initial render
    useEffect(() => {
        fetchData();
    }, [location.search]);

    useEffect(() => {
        if (selectedPackage) {
            const packageData = packages.find((pkg) => pkg._id === selectedPackage);
            console.log(packageData, 'packageData')
            // if (packageData) {
            //     // setPackageAmount(packageData.packageAmount);
            //     updateState({ packageAmount: packageData.packageAmount })
            // }
        }
    }, [selectedPackage, packages]);

    const [selectedServiceData, setSelectedServiceData] = useState(null);
    // console.log(selectedServiceData, 'selectedPackageData')

    const [state, setState] = useState({
        isLoading: false,
        category: '',
        service: '',
        packageName: '',
        social_link: '',
        quantity: null,
        // packageAmount: filteredPackage?.packageAmount || '',
        packageAmount: null,
        totalCharge: 0,
        orderPlan: ''
    });

    const { isLoading, category, service, packageName, social_link, quantity, packageAmount, totalCharge, orderPlan } = state;

    const updateState = data => setState(state => ({ ...state, ...data }))

    // console.log(state, "updateState")
    const handelQuantityChange = (event) => {
        const newQuantity = parseInt(event.target.value);
        const newTotalCharge = newQuantity * packageAmount;
        updateState({
            quantity: newQuantity,
            totalCharge: newTotalCharge.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
            })
        });
    };

    const handlePackageChange = (event) => {
        // updateState({ quantity: 0, totalCharge: 0 })
        const selectedPackageId = event.target.value;
        console.log(selectedPackageId, 'selectedPackageId')
        const selectedPackage = packages.find(pkg => pkg._id === selectedPackageId);
        console.log(selectedPackage, 'selectedPackage')
        updateState({
            quantity: 1,
            totalCharge: selectedPackage.packageAmount.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
            })
        })
        setSelectedPackage(selectedPackageId);
        updateState({ packageName: selectedPackage.packageName, packageAmount: selectedPackage.packageAmount });
        // if (selectedPackage) {
        //     setSelectedPackage(selectedPackageId);
        //     updateState({ packageName: selectedPackage.packageName, packageAmount: selectedPackage.packageAmount });
        // }
    };

    const [validationErrors, setValidationErrors] = useState({});

    const validateForm = () => {
        const errors = {};
        if (!selectedService) {
            errors.service = 'Please select a service';
        }
        setValidationErrors(errors);

        return Object.keys(errors).length === 0;
    };
    const UserData = user
    // console.log(UserData)
    const UserId = user?._id
    // console.log(filteredPackage, 'filteredPackage')

    const handelSubmitOrder = async (e) => {
        e.preventDefault();
        // const isValid = validateForm();
        if (isChecked) {
            updateState({ isLoading: true });
            try {
                const formData = new FormData();
                formData.append('user', JSON.stringify(UserData));
                formData.append('userId', UserId);
                formData.append('category_name', filteredPackage?.category_name);
                formData.append('service_name', filteredPackage?.service_name);
                formData.append('package_name', packageName);
                formData.append('quantity', quantity);
                formData.append('social_link', social_link);
                formData.append('totalCharge', totalCharge);
                formData.append('orderPlan', orderPlan);
                // formData.append('paymentInfo', formState.status);
                const url = `${host}/orders/create-order`;
                const response = await axios({
                    method: 'post',
                    url,
                    data: formData,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                // console.log(response, 'response')
                if (response.data.success == true) {
                    // alert("Order created successfully")
                    updateState({
                        isLoading: false,
                        category: '',
                        service: '',
                        packageName: '',
                        social_link: '',
                        quantity: null,
                        packageAmount: null,
                        totalCharge: null,
                    })
                    toast("Order Successfully!", {
                        type: "success",
                    });
                    nevigate('/user-profile')
                    // window.location.href = '/'
                } else {
                    updateState({ isLoading: false });
                    alert("Got error: " + response.data)
                    toast("Failed ", {
                        type: "error",
                    });
                }
            } catch (error) {
                updateState({ isLoading: false });
                console.error('Error:', error);
                toast("Error:", {
                    type: "error",
                });
            }
        } else {
            // If checkbox is not checked, show an alert or do nothing
            alert('Please check the checkbox to proceed.');
        }
    };

    // const __handleSubmitOrder = () => {
    //     __postApiData(`/orders/placeOrder`, { search })
    //         .then(res => {
    //             if (res.response.response_code === "200") {
    //                 return updateState({
    //                     leads: res.data
    //                         .map((item, i) => ({ ...item, sr_no: i + 1 }))
    //                         .reverse(),
    //                     isLoading: false,
    //                     isAsyncLoading: false,
    //                 })
    //             }
    //             return updateState({
    //                 leads: [],
    //                 isLoading: false,
    //                 isAsyncLoading: false,
    //             })
    //         })
    //         .catch(error => {
    //             console.error(error)
    //             return updateState({
    //                 leads: [],
    //                 isLoading: false,
    //                 isAsyncLoading: false,
    //             })
    //         })
    // }

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoading(false);
        }, 2000);

        return () => clearTimeout(timeout); // Clean up timeout on component unmount
    }, []);

    const orderPlans = [

        {
            "id": 1,
            "name": "One-Time Order",
            "value": "One-Time",
            "description": "Order will be placed only once"
        },
        {
            "id": 2,
            "name": "Order Every Seven Days",
            "value": "Every-Seven-Days",
            "description": "Order will be placed automatically every seven days"
        },
        {
            "id": 3,
            "name": "Order Every Thirty Days",
            "value": "Every-Thirty-Days",
            "description": "Order will be placed automatically every thirty days"
        }
    ]

    return (
        <>
            <PageHeader
                title={filteredPackage?.category_name ? filteredPackage?.category_name : "No services"}
                // title={filteredPackage?.category_name ? filteredPackage?.category_name : "No services" + "  " + filteredPackage?.service_name}
                // title2={filteredPackage?.category_name + " || " + filteredPackage?.service_name}
                desc={filteredPackage?.packageTask}
                desc2={filteredPackage?.packageDays}
            // blogtags='0'
            />
            {loading ? <LoadingSpinner /> :
                <div className="container  mt-5"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <div className='col-xl-8 col-lg-7 col-md-12 order-0 order-lg-1'>
                        <div className='register-wrap p-5 bg-white shadow rounded-custom mt-5 mt-lg-0 mt-xl-0'>
                            <div className='row'>
                                <img
                                    src={`${API_URL}/${filteredPackage?.category?.icon}`}
                                    loading="lazy"
                                    width="24"
                                    height={50}
                                    alt='icon'
                                    className='col mb-2'
                                />
                                {/* <img
                                src={`${API_URL}/${filteredPackage?.service?.serviceIcon}`}
                                loading="lazy"
                                width="24"
                                height={50}
                                alt='icon'
                                className='col mb-2'
                            /> */}
                                <h3 className='fw-medium h4 text-center'>
                                    {filteredPackage ? filteredPackage.category_name + " " + filteredPackage.service_name : 'No Packages Available For This Service'}
                                </h3>
                            </div>
                            <form className='mt-4 register-form'
                                onSubmit={handelSubmitOrder}
                            >
                                <div className='row'>
                                    <div className='col-12'>
                                        <label htmlFor='selectpackage' className='mb-1'>
                                            Choose Package <span className='text-danger'>*</span>
                                        </label>
                                        <div className='input-group mb-3 '>
                                            <select
                                                className='form-control form-select rounded-pill'
                                                name='packageName'
                                                id='selectpackage'
                                                required
                                                data-msg='Select Package.'
                                                onChange={handlePackageChange}
                                            // onChange={(e) => {
                                            //     const selectedPackage = e.target.value;
                                            //     updateState({ packageName: selectedPackage });
                                            //     // console.log(packageName, "packageName")
                                            //     console.log(selectedPackage, "packageName");
                                            // }}
                                            >
                                                <option value="" disabled>Select Packages</option>
                                                {PackagesbyServiceId?.map(packages => (
                                                    <option key={packages._id} value={packages._id}>
                                                        {packages?.packageName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <label htmlFor='social-link' className='mb-1'>
                                            Social Media Link . <span className='text-danger'>*</span>
                                        </label>
                                        <div className='input-group mb-3'>
                                            <input
                                                id='social-link '
                                                className='form-control rounded-pill  '
                                                placeholder='Paste Your Social Media Account  Link Here'
                                                style={{ height: '60px' }}
                                                value={social_link}
                                                name='social_link'
                                                required
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    updateState({ social_link: selectedValue });
                                                }}
                                            >
                                            </input>
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <label className='mb-1'>
                                            Quantity:
                                            {/* <span className='text-danger'>*</span> */}
                                        </label>
                                        {/* <div className='input-group mb-3 form-control rounded-pill'>
                                        Charge :<div className=''> {FinalAmount}</div>
                                    </div> */}
                                        <input
                                            className='input-group mb-3 form-control rounded-pill text-center'
                                            type="number" value={quantity}
                                            // onChange={(e) => {
                                            //     updateState(
                                            //         { quantity: e.target.value }
                                            //     );
                                            // }}
                                            required
                                            onChange={handelQuantityChange}
                                        // readOnly
                                        />
                                    </div>
                                    <div className='col-sm-6'>
                                        <label className='mb-1'>
                                            Charge:
                                            {/* <span className='text-danger'>*</span> */}
                                        </label>
                                        {/* <div className='input-group mb-3 form-control rounded-pill '>
                                        &nbsp;&nbsp; Charge : &nbsp;<div className='text-primary text-bold'> final ampunt</div>
                                    </div> */}
                                        <input
                                            className='input-group mb-3 form-control text center  rounded-pill'
                                            type="text"
                                            value={totalCharge}
                                            readOnly
                                        />
                                    </div>
                                    <div className='col-12 col-sm-12'>
                                        <label className='mb-1'>Choose a Plan:</label>
                                        {orderPlans?.map((plan) => (
                                            <div className='form-check' key={plan.id}>
                                                <input
                                                    className='form-check-input'
                                                    type='radio'
                                                    name='orderPlan'
                                                    required
                                                    id={plan.value}
                                                    value={plan.value}
                                                    // onChange={handleOrderPlanChange}
                                                    onChange={(e) => {
                                                        const selectedValue = e.target.value;
                                                        console.log(selectedValue, "orderPlan")
                                                        updateState({ orderPlan: selectedValue });
                                                    }}
                                                />
                                                <label className='form-check-label' htmlFor={plan.value}>
                                                    {plan.name}
                                                    <span className="text-muted"> ({plan.description})</span>
                                                </label>
                                            </div>
                                        ))}
                                    </div>

                                    <div className='col-12'>
                                        <div className='form-check'>
                                            <input
                                                className='form-check-input'
                                                type='checkbox'
                                                value=''
                                                id='flexCheckChecked'
                                                checked={isChecked}
                                                onChange={handleCheckboxChange}
                                            />
                                            <label
                                                className='form-check-label small'
                                                htmlFor='flexCheckChecked'
                                            >
                                                Yes, I'd like to receive occasional marketing emails from
                                                us. I have the right to opt out at any time.
                                                {showMore && (
                                                    <>
                                                        <br />
                                                        Please ensure your username and social media link are accurate for a seamless experience.
                                                        We take every step to ensure secure transactions.
                                                        We will not be responsible for payment failures.
                                                        <Link className="btn-link" to='#'> View privacy policy</Link>.
                                                        <button className="btn btn-link p-0" onClick={toggleDescription}>Show Less</button>
                                                    </>
                                                )}
                                                {!showMore && (
                                                    <>
                                                        <button className="btn btn-link p-0" onClick={toggleDescription}>Show More</button>
                                                    </>
                                                )}
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        {localStorage.getItem("token") ? (
                                            isLoading ? (
                                                <button
                                                    // type='submit'
                                                    className='btn btn rounded-pill mt-4 d-block w-100'
                                                >
                                                    <LoadingSpinner />
                                                </button>
                                            ) : (
                                                <button
                                                    type='submit'
                                                    className='btn btn-primary rounded-pill mt-4 d-block w-100'
                                                >
                                                    Order
                                                </button>
                                            )
                                        ) : (
                                            <>
                                                <Link to="/login">
                                                    <button
                                                        type='submit'
                                                        className='btn btn-primary mt-4 d-block w-100'
                                                    >
                                                        Please Login First
                                                    </button>
                                                </Link>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div >
                </div >
            }
        </>
    );
};

export default OrderService;

const LoadingSpinner = () => {
    return (
        <div className="container mt-5 text-center">
            <Spin size="large" />
        </div>
    );
};

