import React from 'react';
import PageHeader from '../components/common/PageHeader';
import PageMeta from '../components/common/PageMeta';
import ContactBox from '../components/contact/ContactBox';
import ContactFormTwo from '../components/contact/ContactFormTwo';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';

const Contact = () => {
  return (
    <Layout>
      <PageMeta
        title="Contact - Snapbooster"
        description="snapbooster is best Social Media Marketing website"
        keywords="snapbooster, snapbooster, snapbooster"
      />
      <Navbar navDark />
      <PageHeader
        title="Contact Us"
        contactdesc="At SnapBooster, we're dedicated to providing seamless support to our clients.
         Our team ensures a hassle-free experience, offering valuable insights and expertise.
          We proactively manage quality service delivery and optimize supply chain processes.
           Get in touch with us today for client-centric solutions tailored to your needs."
      />
      <ContactBox />
      {/* <ContactFormTwo /> */}
      <FooterOne
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat bottom right",
        }}
      />
    </Layout>
  );
};

export default Contact;
