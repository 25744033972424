import React from 'react';
// import PageHeader from '../components/common/PageHeader';
import PageMeta from '../components/common/PageMeta';
// import CtaSubscribe from '../components/cta/CtaSubscribe';
import FaqTwo from '../components/faqs/FaqTwo';
// import PriceOne from '../components/prices/PriceOne';
import TestimonialTwo from '../components/testimonial/TestimonialTwo';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
// import SmmFaq from '../components/faqs/Smm-Faq';

const Faq = () => {
    return (
        <Layout>
            <PageMeta
                title='Faq - SnapBooster '
                description="snapbooster is best smm website"
                keywords="snapbooster, snapbooster, snapbooster"
            />
            <Navbar />
            {/* <PageHeader
                title='Price Suit to Your Business'
                desc='Seamlessly actualize client-based users after out-of-the-box value. Globally embrace strategic data through frictionless expertise.'
            /> */}
            {/* <PriceOne paddingTop='ptb-120' /> */}
            {/* <SmmFaq /> */}
            <FaqTwo />
            <TestimonialTwo bgWhite />
            {/* <CtaSubscribe /> */}
            <FooterOne />
        </Layout>
    );
};

export default Faq;
