import React, { Component } from 'react';
import PageMeta from '../../components/common/PageMeta';
import LoginScreen from '../../components/onboard/LoginScreen';
import Layout from '../../layout/Layout';

class Login extends Component {
  render() {
    return (
      <Layout>
        <PageMeta title='Welcome Back - SnapBooster &amp; SMM Platform' />
        <LoginScreen />
      </Layout>
    );
  }
}
export default Login;
