const IconBoxData = [
  {
    id: 1,
    classOption: 'bg-primary',
    icon: 'fal fa-layer-group fa-2x text-white',
    title: 'Good Performance',
    description:
      'Appropriately grow competitive leadership rather than strategic technically sound processes without state .',
  },
  {
    id: 2,
    classOption: 'bg-danger',
    icon: 'fal fa-shield-check fa-2x text-white',
    title: 'Highly Secure',
    description:
      'Appropriately grow competitive leadership rather than strategic technically sound processes without state.',
  },
  {
    id: 3,
    classOption: 'bg-dark',
    icon: 'fal fa-code fa-2x text-white',
    title: 'Fast Development',
    description:
      'Appropriately grow competitive leadership rather than strategic technically sound processes without state.',
  },
];
const FaqOneData2 = [
  {
    id: 1,
    faqTitle: 'How does back pricing work?',
    faqDesc:
      'Progressively e-enable collaborative inexpensive supply chains. Efficiently maintain economically methods of empowerment for synergistic sound scenarios.',
  },
  {
    id: 2,
    faqTitle: 'How do I calculate how much price?',
    faqDesc:
      'Globally benchmark customized mindshare before clicks-and-mortar partnerships. Efficiently maintain economically sound scenarios and whereas client-based progressively.',
  },
  {
    id: 3,
    faqTitle: 'Can you show me an example?',
    faqDesc:
      'Dynamically visualize whereas competitive relationships. Progressively benchmark customized partnerships generate interdependent benefits rather sound scenarios and robust alignments.',
  },
];
const FaqOneData = [
  {
    id: 1,
    faqTitle: 'How can I check my order status?',
    faqDesc:
      'To check your order status, you can log in to your account and navigate to the "Orders" section. There, you will find information about the status of your orders, including whether they are processing, shipped, or delivered.',
  },
  {
    id: 2,
    faqTitle: 'Why are there limits?',
    faqDesc:
      'Limits are in place to ensure fair usage of our services and to prevent abuse. These limits help maintain the integrity of our platform and ensure a positive experience for all users.',
  },
  {
    id: 3,
    faqTitle: 'I placed an order, but the promotion didn\'t start. What should I do?',
    faqDesc:
      `If you have placed an order but the promotion hasn't started, please reach out to our support team via Telegram, email, or online chat on the website. Be sure to provide your order ID and account login details, and we'll assist you in resolving the issue promptly.`,
  },
  {
    id: 4,
    faqTitle: 'Can I get likes, subscribers, and other services for free?',
    faqDesc:
      'We offer various paid services to help grow your social media presence. While we don\'t provide likes, subscribers, or other services for free, we do offer competitive pricing and packages tailored to your needs.',
  },
  {
    id: 5,
    faqTitle: 'I can\'t register using my social network account. What should I do?',
    faqDesc:
      `If you're having trouble registering using your social network account, please try the following troubleshooting steps: 1. Ensure you're using the correct login credentials. 2. Clear your browser cache and cookies. 3. Try using a different browser or device. If the issue persists, please contact our support team for assistance.`,
  },
  {
    id: 6,
    faqTitle: 'Can I use this site without registration?',
    faqDesc:
      'Yes, you can browse our site and view available services without registration. However, registration is required to place orders and access certain features, such as order tracking and customer support.',
  },
  {
    id: 7,
    faqTitle: 'How fast does the promotion start?',
    faqDesc:
      'The promotion typically starts within a few hours of placing your order. However, the exact start time may vary depending on the service and current demand. Rest assured, we strive to initiate promotions as quickly as possible to deliver results promptly.',
  },
  {
    id: 8,
    faqTitle: 'I entered the wrong link while placing the order. What should I do?',
    faqDesc:
      `If you've entered the wrong link while placing your order, please contact our support team immediately.Provide your order ID and the correct link, and we'll do our best to update it before the promotion starts.`,
  },
  {
    id: 9,
    faqTitle: 'How can I request a refund?',
    faqDesc:
      `If you encounter an issue and wish to request a refund, please reach out to our support team via Telegram, email, or online chat on the website. Be sure to include the following information in your refund request: 1. Order ID 2. Your account login 3. Reasons for refund. Please note that refund requests are only considered if the order has not been completed. Refunds are not possible for completed orders. Allow up to 24 hours for processing refund requests.`,
  },
];

const TestimonialData2 = [
  {
    id: 1,
    authorImg: 'assets/img/testimonial/1.jpg',
    authorName: 'Mr.Rupan Oberoi',
    authorTitle: 'Founder and CEO at Amaara Herbs',
    quoteTitle: 'The Best Template You Got to Have it!',
    authorQuote:
      'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media than ethical',
  },
  {
    id: 2,
    authorImg: 'assets/img/testimonial/2.jpg',
    authorName: 'Joan Dho',
    authorTitle: 'Founder and CTO',
    quoteTitle: 'Best Template for SAAS Company!',
    authorQuote:
      'Dynamically create innovative core competencies with effective best practices promote innovative infrastructures.',
  },
  {
    id: 3,
    authorImg: 'assets/img/testimonial/3.jpg',
    authorName: 'Ranu Mondal',
    authorTitle: 'Lead Developer',
    quoteTitle: 'It is undeniably good!',
    authorQuote:
      'Rapidiously supply client-centric e-markets and maintainable processes progressively engineer',
  },
  {
    id: 4,
    authorImg: 'assets/img/testimonial/4.jpg',
    authorName: 'Mr.Rupan Oberoi',
    authorTitle: 'Founder and CEO at Amaara Herbs',
    quoteTitle: 'The Best Template You Got to Have it!',
    authorQuote:
      'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media than ethical',
  },
];

const TestimonialData = [
  {
    id: 1,
    authorImg: 'assets/img/testimonial/1.jpg',
    authorName: 'Sarah Johnson',
    authorTitle: 'Digital Marketing Manager',
    quoteTitle: 'Boosted Our Social Media Presence!',
    authorQuote:
      'Working with this team has been a game-changer for our social media marketing efforts. Their dedication and expertise have led to remarkable growth in our follower count and engagement rates.',
  },
  {
    id: 2,
    authorImg: 'assets/img/testimonial/2.jpg',
    authorName: 'Alex Ramirez',
    authorTitle: 'Social Media Specialist',
    quoteTitle: 'Excellent Results in Record Time!',
    authorQuote:
      'Thanks to this team, we were able to achieve excellent results in record time. Their strategic approach to social media marketing has significantly increased our brand visibility and engagement.',
  },
  {
    id: 3,
    authorImg: 'assets/img/testimonial/3.jpg',
    authorName: 'Emily Chen',
    authorTitle: 'Marketing Director',
    quoteTitle: 'Increased Brand Visibility and Recognition!',
    authorQuote:
      'This team helped us establish a strong presence on various social media platforms, resulting in increased brand visibility and recognition. Their tailored strategies have been instrumental in our success.',
  },
  {
    id: 4,
    authorImg: 'assets/img/testimonial/4.jpg',
    authorName: 'David Smith',
    authorTitle: 'Social Media Manager',
    quoteTitle: 'Exceptional Service and Support!',
    authorQuote:
      'We re incredibly impressed with the level of service and support provided by this team.They go above and beyond to ensure our social media campaigns are successful and deliver results.',
  },
];


const TestimonialOneData = [
  {
    id: '#testimonial-tab-1',
    activeClass: 'active',
    title: 'The Best Template You Got to Have it!',
    desc: 'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media infrastructures rather than ethical sticky alignments rather than global. Plagiarize technically sound total linkage for leveraged value media web-readiness and premium processes.',
    authorName: 'Joe Richard',
    authorDesn: 'Visual Designer',
    authorThumb: 'assets/img/testimonial/t-1.jpg',
    authorAvatar: 'assets/img/testimonial/1.jpg',
  },
  {
    id: '#testimonial-tab-2',
    title: 'Embarrassed by the First Version.',
    desc: 'Energistically streamline robust architectures whereas distributed mindshare. Intrinsicly leveraother backend growth strategies through 24/365 products. Conveniently pursue revolutionary communities for compelling process improvements.',
    authorName: 'Rupan Oberoi',
    authorDesn: 'Web Designer',
    authorThumb: 'assets/img/testimonial/t-2.jpg',
    authorAvatar: 'assets/img/testimonial/2.jpg',
  },
  {
    id: '#testimonial-tab-3',
    title: 'The Best Template You Got to Have it!',
    desc: 'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media infrastructures rather than ethical sticky alignments rather than global. Plagiarize technically sound total linkage for leveraged value media web-readiness and premium processes.',
    authorName: 'Joe Richard',
    authorDesn: 'Visual Designer',
    authorThumb: 'assets/img/testimonial/t-3.jpg',
    authorAvatar: 'assets/img/testimonial/3.jpg',
  },
  {
    id: '#testimonial-tab-4',
    title: 'The Best Template You Got to Have it!',
    desc: 'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media infrastructures rather than ethical sticky alignments rather than global. Plagiarize technically sound total linkage for leveraged value media web-readiness and premium processes.',
    authorName: 'Joe Richard',
    authorDesn: 'Visual Designer',
    authorThumb: 'assets/img/testimonial/t-4.jpg',
    authorAvatar: 'assets/img/testimonial/4.jpg',
  },
  {
    id: '#testimonial-tab-5',
    title: 'The Best Template You Got to Have it!',
    desc: 'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media infrastructures rather than ethical sticky alignments rather than global. Plagiarize technically sound total linkage for leveraged value media web-readiness and premium processes.',
    authorName: 'Joe Richard',
    authorDesn: 'Visual Designer',
    authorThumb: 'assets/img/testimonial/t-5.jpg',
    authorAvatar: 'assets/img/testimonial/5.jpg',
  },
];

export { IconBoxData, FaqOneData, TestimonialData, TestimonialOneData };
