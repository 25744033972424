import React, { useState } from 'react'
import AdminContext from "./adminContext";
import axios from 'axios';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const AdminState = (props) => {
  // port
  // const API_URL = "http://localhost:8001";
  const host2 = process.env.REACT_APP_HOST;
  // const host = "http://192.168.29.19/api/v2"
  // const host = "http://localhost:8001/api/v2"
  // http://www.snapbooster.us:8001/api/v2
  // live Host url
  // const host = "https://snap-booster.onrender.com/api/v2"
  // const API_URL = "https://snap-booster.onrender.com";
  const host = process.env.REACT_APP_HOST;
  const API_URL = process.env.REACT_APP_API_URL;
  // error
  const [error, setError] = useState(null);

  // register / Signup  Api
  const Rigester = async (data) => {
    setError(null);
    // API Call        
    // console.log(data.email, "email")
    localStorage.setItem("email", data.email);
    // let response = await axios.post(`${host2}/api/auth/register`, data)
    let response = await axios.post(`${host}/auth/register`, data)
      .then((response) => {
        // console.log("Response", response);
        if (response.data.success) {
          //  localStorage.setItem("token", response.data.authtoken);
          localStorage.setItem("email", data.email);
          toast("Rigestered successfully Please Login!", {
            type: "success",
          });
        }
        return true
      })
      .catch((err) => {
        if (err && err.response) setError(err.response.data.error);
        // console.log("response",response)
        console.log('error', err)
        toast(err.response.data.error, {
          type: "error",

        });
        return false
      });
    return response
  };

  //  Login  Api
  const Login = async (data) => {
    setError(null);
    // API Call
    // let response = await axios.post(`${host2}/api/auth/login`, data)
    let response = await axios.post(`${host}/auth/login`, data)

      .then((response) => {
        console.log(".then", response);
        if (response.data.success) {
          // Save the auth token and redirect
          localStorage.setItem("token", response.data.authtoken);
          localStorage.setItem("id", response.data.user._id);
          localStorage.setItem("role", response.data.user.role);
          // to show email in navbar
          localStorage.setItem("email", response.data.user.email);
          // localStorage.setItem("isAdmin", response.data.user.isAdmin);
          toast("Login Successfully", {
            type: "success",
            autoClose: 1000,
            theme: "light",
          });
          // end if
        }
        return true
      })
      .catch((err) => {
        // if (err && err.response) setError(err.response.data.message);
        if (err && err.response) setError(err.response.data.error);
        // alert(err.response.data.error)
        toast(err.response.data.error, {
          type: "error",
          autoClose: 3000,
          theme: "light",
        })
        console.log("error", err)
        return false
      });
    return response
  }
  // verify-otp

  const Verification = async (otp, email) => {

    let response = await axios.post(`${host}/auth/verify-email`, otp, email)
      .then((response) => {
        if (response.data.success) {
          toast("Email Verified Successfully ,Now You Can Login", {
            type: "success",
            autoClose: 3000,
            theme: "light",
          });

        }
        return true

      })
      .catch((err) => {
        if (err && err.response) setError(err.response.data.Error);
        toast(err.response.data.Error && err.response.data, {
          type: "error",
          autoClose: 3000,
          theme: "light",
        })
        console.log("error", err)
        return false
      })
    return response
  }

  // state
  const [user, setUser] = useState('')
  // User Details Api
  const UserDetails = async () => {

    const response = await axios.post(`${host}/auth/userdetails`, {}, {
      headers: {
        Authorization: localStorage.getItem("token"),
      }
    })
      .then((response) => {
        // console.log("response", response)
        // if (response.data.success){    
        //   const UserDetails = response.data.user
        //   setUser(response.data.user)
        //   setUser(response.data.user.username)
        //   console.log("userdetails",response.data.user)
        //     // localStorage.setItem("email", response.data.user.email);      
        //  }
        if (response.status === 200) {
          // console.log("user", response.data.user)
          setUser(response.data.user)
        }
        return true
      })
      .catch((err) => {
        if (err && err.response) setError(err.response?.data?.error);
        console.log("error", err)
        // toast(err.response?.data?.error, {
        //   type: "error",
        // });
        return false
      })
    return response
  }

  const ForgetPassword = async (email) => {

    let response = await axios.post(`${host}/auth/forget-password`, email)
      .then((response) => {
        if (response.data.success) {
          toast("Check Your Email ", {
            type: "success",
            autoClose: 3000,
            theme: "light",
          });
        }
        return true
      })
      .catch((err) => {
        if (err && err.response) setError(err.response.data.Error);
        toast(err.response.data.Error && err.response.data, {
          type: "error",
          autoClose: 3000,
          theme: "light",
        })
        console.log("error", err)
        return false
      })
    return response
  }

  const ResetPassword = async (password, token) => {
    const data = { password, token }
    let response = await axios.put(`${host2}/api/auth/reset-password`, data)
      .then((response) => {
        if (response.data.success === true) {
          toast(
            "Password Updated Successfully "
            // response.message
            ,
            {
              type: "success",
              autoClose: 3000,
              theme: "light",
            });

        }
        else {
          toast(
            "Token Expired  "
            // response.message
            ,
            {
              type: "error",
              autoClose: 3000,
              theme: "light",
            });

        }
        // return true
      })
      .catch((err) => {
        if (err && err.response)
          //  setError(err.response.data.Error);
          toast(err.response.message && err.response.err, {
            type: "error",
            autoClose: 3000,
            theme: "light",
          })
        console.log("error", err)
        return false
      })
    return response
  }

  const UpdateProfile = async (id, username, password, profile) => {
    console.log("id", id)
    const data = { username, password, profile }
    // console.log(data, "data")
    let response = await axios.put(`${host}/auth/update-profile/${id}`, data
      , {
        headers: {
          'Content-Type': 'multipart/form-data',
          // "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      }
    )
      .then((response) => {
        if (response.data.success === true) {
          toast(
            " Updated Successfully ",
            {
              type: "success",
              autoClose: 3000,
              theme: "light",
            });
        }
        return true
      })
      .catch((err) => {
        if (err)
          // setError(err.response.data.err);
          toast(err, {
            type: "error",
            autoClose: 3000,
            theme: "light",
          })
        console.log("err", err)
        return false
      })
    return response
  }

  const [categories, setAllcategories] = useState([])

  const GetAllCategories = async () => {
    try {
      let response = await axios.get(`${host}/category/get-all-categories`,
        // {
        //   headers: {
        //     Authorization: localStorage.getItem("token"),
        //   }
        // }
      )
        .then((response) => {
          // console.log("res", response)
          if (response.status === 200) {
            console.log("category", response)
            setAllcategories(response.data.categories)
          }
        })
        .catch((err) => {
          // console.log("err", err)
          if (err)
            toast(err, {
              type: "error",
              autoClose: 3000,
              theme: "light",
            })
        })
    } catch (error) {
      console.log(error, "catch error")
    }
  }

  const [services, setAllservices] = useState([])
  const GetAllServices = async () => {
    try {
      let response = await axios.get(`${host}/service/get-all-services`,
      // {
      //   headers: {
      //     Authorization: localStorage.getItem("token"),
      //   }
      // }
      )
        .then((response) => {
          // console.log("res", response)
          if (response.status === 200) {
            console.log("services", response)
            setAllservices(response.data.services)
          }
        })
        .catch((err) => {
          // console.log("err", err)
          if (err)
            toast(err, {
              type: "error",
              autoClose: 3000,
              theme: "light",
            })
        })
    } catch (error) {
      console.log(error, "catch error")
    }
  }

  const [packages, setAllPackages] = useState([])
  const GetAllPackages = async () => {
    try {
      let response = await axios.get(`${host}/package/get-all-packages`,
        // {
        //   headers: {
        //     Authorization: localStorage.getItem("token"),
        //   }
        // }
      )
        .then((response) => {
          // console.log("res", response)
          if (response.status === 200) {
            console.log("packages from context", response.data.packages)
            setAllPackages(response.data.packages)
          }
        })
        .catch((err) => {
          // console.log("err", err)
          if (err)
            toast(err, {
              type: "error",
              autoClose: 3000,
              theme: "light",
            })
        })
    } catch (error) {
      console.log(error, "catch error")
    }
  }

  const [PackagesbyServiceId, setPackagesbyServiceId] = useState([])

  const GetPackagesByServiceId = async (serviceId) => {
    try {
      // Extract the 'id' from the URL parameters
      // const urlParams = new URLSearchParams(window.location.search);
      // const serviceId = urlParams.get('id');
      let response = await axios.get(`${host}/package/get-packages-by-service?id=${serviceId}`,
        // {
        //   headers: {
        //     Authorization: localStorage.getItem("token"),
        //   }
        // }
      )
        .then((response) => {
          // console.log("res", response.data.packages)
          if (response.status === 200) {
            console.log("packages Updated", response)
            setPackagesbyServiceId(response.data.packages)
          }
        })
        .catch((err) => {
          console.log("err", err)
          if (err)
            toast(err, {
              type: "error",
              autoClose: 3000,
              theme: "light",
            })
        })
    } catch (error) {
      console.log(error, "catch error")
    }
  }

  const [orders, setOrders] = useState([])
  const GetAllOrders = async () => {
    try {
      let response = await axios.get(`${host}/orders/get-all-orders`,
        // {
        //   headers: {
        //     Authorization: localStorage.getItem("token"),
        //   }
        // }
      )
        .then((response) => {
          // console.log("res", response)
          if (response.status === 200) {
            console.log("orders", response)
            // setOrders(response.data)
          }
        })
        .catch((err) => {
          console.log("err", err)
          if (err)
            toast(err, {
              type: "error",
              autoClose: 3000,
              theme: "light",
            })
        })
    } catch (error) {
      console.log(error, "catch error")
    }
  }

  const [userOrder, setUserOrder] = useState([])
  const GetOrderByUserId = async (userId) => {
    try {
      let response = await axios.get(`${host}/orders/get-order/${userId}`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          }
        }
      )
        .then((response) => {
          // console.log("res", response)
          if (response.status === 200) {
            console.log("orders", response)
            setUserOrder(response.data)
          }
        })
        .catch((err) => {
          console.log("err", err)
          if (err)
            toast(err, {
              type: "error",
              autoClose: 3000,
              theme: "light",
            })
        })
    } catch (error) {
      console.log(error, "catch error")
    }
  }

  // website CMS

  const [welcomeIntro, setwelcomeIntro] = useState([])
  const [howItWorks, setHowItWorks] = useState([])
  const [loading, setLoading] = useState(false)
  const GetHomePageData = async () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      console.log('3 second over');
    }, 3000)
    try {
      let response = await axios.get(`${host}/cms/get-homepage-data`,
        // {
        //   headers: {
        //     Authorization: localStorage.getItem("token"),
        //   }
        // }
      )
        .then((response) => {
          // console.log("res", response)
          if (response.status === 200) {
            // console.log("welcomeIntro", response)
            setwelcomeIntro(response.data.WelcomeData)
            setHowItWorks(response.data.HowItWorks)
            // setTimeout(() => {
            //   setLoading(false)
            //   console.log('3 second over');
            // }, 3000)
            setLoading(false)
          }
        })
        .catch((err) => {
          console.log("err", err)
          setLoading(false)
          if (err)
            toast(err, {
              type: "error",
              autoClose: 3000,
              theme: "light",
            })
        })
    } catch (error) {
      setLoading(false)
      console.log(error, "catch error")
    }
  }

  return (
    <AdminContext.Provider
      value={{
        API_URL, host,
        Rigester, Login, UserDetails, user, Verification, ForgetPassword,
        ResetPassword, UpdateProfile,
        GetAllCategories, categories, GetAllServices, services,
        GetAllPackages, GetPackagesByServiceId, packages, PackagesbyServiceId,
        GetAllOrders, orders, GetOrderByUserId, userOrder,
        welcomeIntro, howItWorks, GetHomePageData, loading
      }}
    >
      {props.children}
    </AdminContext.Provider>
  );


};

export default AdminState;
