import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Services from '../pages/services/Services';
import Login from '../pages/onboard/Login';
import NotFoundScreen from '../components/others/NotFoundScreen';
import PasswordReset from '../pages/onboard/PasswordReset';
// import Pricing from '../pages/Pricing';
import ScrollToTop from '../components/common/ScrollToTop';
import About from '../pages/About';
import Contact from '../pages/Contact';
import Signup from '../pages/onboard/Signup';
// import RequestDemo from '../pages/RequestDemo';
import HomeFourteen from '../themes/index14/HomeFourteen';
// import ServiceForm from '../components/smmForm/ServiceForm';
import ServiceOrder from '../pages/smm-pages/ServiceOrder';
import UserView from '../pages/smm-pages/User-profile-page/user-profile';
import Faq from '../pages/Faq.js';


function index() {

  const userExist = localStorage.getItem("token");

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<HomeFourteen />} />
        <Route exact path="/serviceForm" element={<ServiceOrder />} />
        <Route exact path="/home-fourteen" element={<HomeFourteen />} />
        <Route exact path="/about-us" element={<About />} />
        <Route exact path="/services" element={<Services />} />
        <Route exact path="/contact-us" element={<Contact />} />
        {/* <Route exact path="/pricing" element={<Pricing />} /> */}
        <Route exact path="/faq" element={<Faq />} />
        <Route exact path="/password-reset" element={<PasswordReset />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/signup" element={<Signup />} />
        {/* <Route exact path="/request-for-demo" element={<RequestDemo />} /> */}

        {/* Auth Protected Route */}
        {/* <AuthProtectedRoute exact path="/user-profile" element={<UserView />} /> */}
        <Route
          exact path="/user-profile"
          // element={<UserView /> 
          // }
          element={userExist ? <UserView /> : <Navigate to="/login" />
          }
        />
        <Route element={<NotFoundScreen />} />
      </Routes>
    </BrowserRouter>
  );
}

export default index;
