import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import adminContext from '../../context/adminContext';
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

const PasswordResetScreen = () => {

  const context = useContext(adminContext);
  const { ForgetPassword } = context;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: async (values) => {
      // console.log("values", values)
      setIsSubmitting(true);

      let response = await ForgetPassword(values)
      // console.log("response", response)
      if (response) {
        setTimeout(() => {
          setIsSubmitting(false)
        }, 3000);
      }
      response && validation.resetForm()
      // validation.resetForm()
    }
    // onSubmit: (values) => {
    //   dispatch(userForgetPassword(values, props.history));
    // }
  });

  return (
    <>
      <section
        className='sign-up-in-section bg-dark ptb-60'
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat right bottom",
        }}
      >
        <div className='container'>
          <div className='row align-items-center justify-content-center'>
            <div className='col-lg-5 col-md-8 col-12'>
              <Link to='/' className='mb-4 d-block text-center'>
                <img
                  // src='assets/img/logo-white.png'
                  src="assets/img/smm-logo/snapBooster.png"
                  alt='logo'
                  className='img-fluid'
                />
              </Link>
              <div className='register-wrap p-5 bg-light shadow rounded-custom'>
                <h1 className='fw-bold h3'>Forgot your Password?</h1>
                <p className='text-muted'>
                  Don't worry. Type your email and we will send a password
                  recovery link to your email..
                </p>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                  className='mt-5 register-form'>
                  <div className='row'>
                    {/* <div className='col-sm-12'>
                      <label htmlFor='email' className='mb-1'>
                        Email <span className='text-danger'>*</span>
                      </label>
                      <div className='input-group mb-3'>
                        <input
                          type='email'
                          className='form-control'
                          placeholder='Enter your email'
                          id='email'
                          aria-label='email'
                        />
                      </div>
                    </div> */}
                    <div className="mb-3 col-sm-12">
                      <label htmlFor='email' className='mb-1'>
                        Email <span className='text-danger'>*</span>
                      </label>
                      <div className='input-group mb-3'>
                        <input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                      </div>
                      {validation.touched.email && validation.errors.email ? (
                        <div className='invalid-feedback'>{validation.errors.email}</div>
                      ) : null}
                    </div>
                    <div className='col-12'>
                      <button
                        type='submit'
                        className='btn btn-primary mt-3 d-block w-100'
                      >
                        {isSubmitting ? 'Check Your Email' : ' Reset Password'}
                      </button>
                    </div>
                  </div>
                  <p className='font-monospace fw-medium text-center mt-3 pt-4 mb-0'>
                    <Link to='/login' className='text-decoration-none'>
                      Back to login page
                    </Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PasswordResetScreen;
